import Entity from "../../../class/abstract/Entity";
import Store from "../../_account/Stores/Store";
import License from "../../_account/Licenses/License";

class PaymentTerminal extends Entity {
    name = "";
    reference = "";
    target = "";
    interfaceType = "";
    brand = "";
    model = "";
    ipAddress = "";
    ipPort = 0;
    bltPort = "";
    usbPort = "";
    serialPort = "";
    serialBaudRate = 0;
    serialDataBits = 0;
    serialParity = "";
    serialStopBits = 0;
    serialFlowControl = "";
    allowsDisconnectedTerminal = null;
    active = null;
    protocol = null;
    store_id = null;
    store = {};
    license_id = null;
    license = {};
    last_histo_author = null;

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                if(key === "store")
                    Reflect.set(this, key, new Store(datas[key]));
                else if(key === "license")
                    Reflect.set(this, key, new License(datas[key]));
                else
                    Reflect.set(this, key, datas[key]);
            }
        }
    }
}

export default PaymentTerminal;
