import React, {useEffect, useState} from "react";
import ActionListHeaderSheet from "../../sheet/ActionListHeaderSheet";
import FormBuilder from "../../../class/tool/FormBuilder";

const CategoryHeaderSheet = props => {
    const { object, values, handleAction } = props;
    const [ actions, setActions ] = useState([]);

    const buildActions = () => {
        const env = JSON.parse(localStorage.getItem("env"))
        const settings = JSON.parse(localStorage.getItem("storeSettings"))
        let actionsTmp = []

        if (env.type === "company" || (env.type === "store" && settings.allowedProductCreation) || object.sharedUuid === null) {
            actionsTmp.push({
                title: "Supprimer",
                type: "remove"
            })
        }

        setActions(actionsTmp)
    }

    useEffect(() => {
        buildActions()
    }, [])

    return (
        <div className="headerSheet">
            <div className="infos">
                <p className={"titleSheet alone"}>{values.name}</p>
            </div>
            <ActionListHeaderSheet actions={actions} handleAction={handleAction}/>
        </div>
    );
}

export default CategoryHeaderSheet;
