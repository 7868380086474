import React, { useState } from "react";
import FormBuilder from "../../../class/tool/FormBuilder";

const ProductFormSheet = props => {
    const storeSettings = JSON.parse(localStorage.getItem("storeSettings"))
    const { object, values, errors, updated, setGlobalError, handleChange, handleSave } = props
    const [ edit, setEdit ] = useState("")
    const [ rows, setRows ] = useState([
        {
            label: "Nom du produit",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "Libellé de la touche",
            attribute: "label",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Libellé",
            emptyText: "Aucun",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "Description",
            attribute: "description",
            inputType: "textarea",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Description",
            emptyText: "Aucune",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "Réference",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Réference",
            emptyText: "Aucune",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
    ])

    const buildRow = row => {
        if ((row.edit === undefined || row.edit) && (edit === row.attribute || ["color"].includes(row.inputType)))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit);
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit);
    }
    const handleEdit = attribute => {
        setEdit(attribute);
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict);
    }
    const reinitEdit = () => {
        setEdit("");
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();

        handleSave();
    }

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit }>
            {
                <div className="clearing">
                    {
                        rows.map((row, index) => (
                            <div key={ index } className="clearing">
                                <label className={ row.classnameLabel }>{ row.label }</label>
                                {
                                    buildRow(row)
                                }
                            </div>
                        ))
                    }
                </div>
            }
        </form>
    );
}

export default ProductFormSheet;
