import React, {useContext, useEffect, useState} from "react";
import ActionListHeaderSheet from "../../sheet/ActionListHeaderSheet";
import LoaderCircle from "../../loader/LoaderCircle";
import CategoryController from "../../../stories/_catalog/Categories/CategoryController";
import StringTools from "../../../class/tool/StringTools";
import BackofficeContext from "../../../context/BackofficeContext";

const SubCategoryHeaderSheet = props => {
    const { object, values, moving, handleAction } = props;
    const { setErrorText } = useContext(BackofficeContext);
    const [ actions, setActions ] = useState([]);
    const [ moveWindow, setMoveWindow ] = useState(null);
    const [ categories, setCategories ] = useState(null);
    const [ categoryToMoved, setCategoryToMoved ] = useState(null);
    const [ confirmWindow, setConfirmWindow ] = useState(false);

    const buildActions = () => {
        const env = JSON.parse(localStorage.getItem("env"));
        const settings = JSON.parse(localStorage.getItem("storeSettings"));
        let actionsTmp = [];

        if (env.type === "company" || (env.type === "store" && settings.allowedProductCreation) || object.sharedUuid === null) {
            actionsTmp.push({
                title: "Supprimer",
                type: "remove"
            });
        }

        setActions(actionsTmp)
    }
    const showMoveWindow = value => {
        if (value === null)
            setCategoryToMoved(null);

        setMoveWindow(value);
    }
    const confirmMove = () => {
        if (categoryToMoved === null) {
            setErrorText("Vous devez sélectionner une catégorie");
            return;
        }

        setConfirmWindow(true);
    }
    const getCategories = () => {
        const controller = new CategoryController()
        controller._callback = returnGetCategories
        controller.index(object.catalog_id, "", 0, 0, true, "name", "asc")
    }
    const returnGetCategories = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                list = list.sort((s1, s2) => {
                    if (StringTools.removeAccents(s1.name.toLocaleLowerCase()) > StringTools.removeAccents(s2.name.toLocaleLowerCase())) return 1
                    else if (StringTools.removeAccents(s1.name.toLocaleLowerCase()) < StringTools.removeAccents(s2.name.toLocaleLowerCase())) return -1
                    else return 0
                });
                setCategories(list);
                break;
            default:
                setErrorText("Impossible de récupérer les catégories");
                showMoveWindow(null);
                break;
        }
    }
    const checkItemStyle = (type, category) => {
        let style = "";

        if (object.category_id === category.id) {
            style = " actu";
        }

        if (categoryToMoved !== null && categoryToMoved.id === category.id) {
            style += " selected";
        }

        return style;
    }
    const buildListCategories = () => {
        if (categories === null) {
            return <LoaderCircle display="loader logWait smaller marginTop" strokeWidth="8" stroke="#9C9C9C" />;
        }

        return <div className={"listItemInOverbox"}>
            {
                categories.map((category, indexItem) => (
                    <div key={indexItem} className={"containerItem"}>
                        <p className={"item" + checkItemStyle("categories", category)} onClick={ () => { selectCategoryToMoved({ type: "categories", id: category.id, name: category.name }) } }>{category.name}</p>
                    </div>
                ))
            }
        </div>;
    }
    const selectCategoryToMoved = datas => {
        if (object.category_type === datas.type && object.category_id === datas.id)
            return;

        setCategoryToMoved(datas);
    }
    const okToMove = () => {
        setConfirmWindow(false);

        if (categoryToMoved === null) {
            setErrorText("Vous devez sélectionner une catégorie");
            return;
        }

        handleAction("move", categoryToMoved);
    }
    const abortMove = () => {
        setConfirmWindow(false);
    }

    useEffect(() => {
        buildActions()
    }, []);
    useEffect(() => {
        if (moveWindow !== null)
            getCategories();
        else
            setCategories(null);
    }, [moveWindow]);
    useEffect(() => {
        if (!moving && categoryToMoved !== null)
            setCategoryToMoved(null);
    }, [moving]);

    return (<>
        <div className="headerSheet">
            <div className="infos">
                <p className={"titleSheet"}>{values.name}</p>
                <div className={"ariane"}>
                    <p className={"link"} onClick={() => { showMoveWindow("cat") }}>{object.category_name}</p>
                </div>
            </div>
            <ActionListHeaderSheet actions={actions} handleAction={handleAction}/>
        </div>
        {
            moveWindow !== null
            && <div className="overlayer hover">
                <div className="wrapOverbox">
                    <div className="overbox hover choices extend">
                        <div className="form">
                            <p className="titleForm center">Déplacer la sous-catégorie</p>
                            <p className={"warning"}>
                                <strong>Attention</strong> : Déplacer la sous-catégorie dans une autre catégorie modifiera automatiquement ses règles de TVA si celle-ci sont différentes.
                            </p>
                            {
                                buildListCategories()
                            }
                            <button className={"submit " + (moving ? "hide" : "")} onClick={confirmMove}>
                                {moving ? "Déplacement..." : "Déplacer"}
                                <LoaderCircle display="loader submitForm " hide={!moving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF"/>
                            </button>
                            <button className="cancel align" onClick={() => { showMoveWindow(null) }}>Fermer</button>
                            <div className="clearing"/>
                        </div>
                    </div>
                </div>
            </div>
        }
        {
            (confirmWindow && categoryToMoved !== null)
            && <div className="overlayer hover">
                <div className="wrapOverbox">
                    <div className="overbox hover validation">
                        <div className="form noPadding">
                            <p className="titleForm center">Confirmer le déplacement</p>
                            <p className={"text"}>
                                Êtes-vous sûr de vouloir déplacer le produit dans la catégorie "<strong>{ categoryToMoved.name }</strong>" ?
                            </p>
                            <button className={"submit " + (moving ? "hide" : "")} onClick={okToMove}>Confirmer</button>
                            <button className="cancel align" onClick={abortMove}>Annuler</button>
                            <div className="clearing"/>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>);
}

export default SubCategoryHeaderSheet;
