import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import NewObject from "../../../overbox/sheet/product/NewObject";
import ObjectSheet from "../../../sheet/ObjectSheet";
import Listing from "../../../table/Listing";
import usePrevious from "../../../../class/tool/usePrevious";
import FormBuilder from "../../../../class/tool/FormBuilder";
import CatalogController from "../../../../stories/_catalog/Catalogs/CatalogController";
import CategoryController from "../../../../stories/_catalog/Categories/CategoryController";
import SubCategoryController from "../../../../stories/_catalog/SubCategories/SubCategoryController";
import ProductController from "../../../../stories/_catalog/Products/ProductController";
import PricelistController from "../../../../stories/_catalog/Pricelists/PricelistController";
import VatRateController from "../../../../stories/_catalog/VatRates/VatRateController";
import ListingContext from "../../../../context/ListingContext";
import "../../../../css/page/content/product/Product.css";

const Products = props => {
    const { page } = props;
    const item = "products";
    const itemClass = "product";
    const titleWindow = "Produits";
    const placeholderSearch = "un produit";
    const titleNbItems = "produits";
    const emptyList = "Aucun produit";
    const textRemoveButton = "ce produit";
    const activeHistory = false;
    const env = JSON.parse(localStorage.getItem("env"));
    const settings = JSON.parse(localStorage.getItem("storeSettings"));
    const defaultCatalog = JSON.parse(localStorage.getItem("catalog"));
    const queryParams = new URLSearchParams(window.location.search);
    const [ pageSelect, setPageSelect ] = useState(page != null ? page : 1);
    const [ loading, setLoading ] = useState(true);
    const [ categories, setCategories ] = useState([
        {
            value: "Toutes les catégories",
            id: null,
            type: ""
        }, {
            value: "----------",
            id: null,
            type: ""
        }
    ]);
    const [ model, setModel ] = useState([]);
    const [ list, setList ] = useState([]);
    const [ options, setOptions ] = useState([]);
    const [ secondaryOptions, setSecondaryOptions ] = useState([]);
    const [ filterValues, setFilterValues ] = useState({});
    const [ filterRows, setFilterRows ] = useState([]);
    const [ pagination, setPagination ] = useState(null);
    const [ perPage, setPerPage ] = useState(25);
    const [ sortingName, setSortingName ] = useState("updated_at");
    const [ sortingValue, setSortingValue ] = useState("desc");
    const [ storeId, setStoreId ] = useState(null);
    const [ catalogId, setCatalogId ] = useState(queryParams.get("catalog") !== null ? queryParams.get("catalog") : (Object.keys(defaultCatalog).length > 0 ? defaultCatalog.id : null));
    const [ pricelistId, setPricelistId ] = useState(queryParams.get("pricelist") !== null ? queryParams.get("pricelist") : null);
    const [ input, setInput ] = useState(queryParams.get("input") !== null ? queryParams.get("input") : "");
    const [ categoryId, setCategoryId ] = useState(queryParams.get("category_id") !== null ? queryParams.get("category_id") : null);
    const [ categoryType, setCategoryType ] = useState(queryParams.get("category_type") !== null ? queryParams.get("category_type") : null);
    const [ stores, setStores ] = useState([]);
    const [ catalogs, setCatalogs ] = useState([]);
    const [ pricelists, setPricelists ] = useState([]);
    const [ vatRates, setVatRates ] = useState([]);
    const prevPerPage = usePrevious(perPage);
    const prevCatalogId = usePrevious(catalogId);
    const prevCategoryId = usePrevious(categoryId);
    const prevCategoryType = usePrevious(categoryType);
    const prevInput = usePrevious(input);

    const initStores = () => {
        if (env.type === "store")
            return

        let storesTmp = [
            {
                value: "Toutes les boutiques",
                id: null
            }, {
                value: "----------",
                id: null
            }
        ]

        for (let i in env.stores) {
            storesTmp.push({
                value: env.stores[i].name,
                id: env.stores[i].id,
                object: env.stores[i]
            })
        }

        setStores(storesTmp)
    }
    const updateModel = () => {
        const catalogs = JSON.parse(localStorage.getItem("catalogs"))
        let modelsTmp = [
            {
                "class": "name",
                "sortingParam": "name",
                "title": "Nom",
                "attributes": ["name"],
                "type": "text"
            },
            {
                "class": "price",
                "sortingParam": null,
                "title": "Prix",
                "attributes": ["price"],
                "type": "price"
            },
            {
                "class": "vat",
                "sortingParam": null,
                "title": "TVA",
                "attributes": ["vat"],
                "type": "vat"
            },
            {
                "class": "reference",
                "sortingParam": "reference",
                "title": "Référence",
                "attributes": ["reference"],
                "type": "text"
            }
        ]
        let placeCategories = 1;
        let placeSubCategories = 1;

        if (catalogs.length > 1 && catalogId === null) {
            modelsTmp.splice(1, 0, {
                "class": "catalogName",
                "sortingParam": null,
                "title": "Catalogue",
                "attributes": ["catalog_name"],
                "type": "catalogName"
            })
        }

        if (categoryId === null) {
            placeCategories = modelsTmp.findIndex(_ => _.type === "catalogName") > 0 ? 2 : 1;
            placeSubCategories = placeCategories + 1;

            modelsTmp.splice(placeCategories, 0, {
                "class": "category",
                "sortingParam": null,
                "title": "Catégorie",
                "attributes": ["category_name"],
                "type": "text"
            });
        }

        if (categories.find(_ => _.type === "subcategory") !== undefined) {
            modelsTmp.splice(placeSubCategories, 0, {
                "class": "category",
                "sortingParam": null,
                "title": "Sous-categorie",
                "attributes": ["subcategory_name"],
                "type": "text"
            });
        }

        setModel(modelsTmp)
    }
    const initFilters = () => {
        const catalogs = JSON.parse(localStorage.getItem("catalogs"))
        let filtersTmp = []
        let marginLeft = ""

        if (catalogs.length > 1) {
            filtersTmp.push({
                attribute: "catalog_id",
                inputType: "select",
                returnType: "int",
                list: catalogs,
                dictionary: null,
                classnameInput: "select marginTop leftAlign"
            })

            marginLeft = " marginLeft"
        }

        if (env.type === "company") {
            filtersTmp.push({
                attribute: "store_id",
                inputType: "select",
                returnType: "int",
                list: stores,
                dictionary: null,
                classnameInput: "select marginTop" + marginLeft
            })

            marginLeft = " marginLeft"
        }

        filtersTmp.push({
            attribute: "category_id",
            inputType: "select",
            returnType: "int",
            list: categories,
            dictionary: null,
            classnameInput: "select marginTop" + marginLeft
        })
        filtersTmp.push({
            attribute: "input",
            inputType: "text",
            returnType: "string",
            classnameInput: "text marginTop marginLeft",
            placeholder: "Rechercher " + placeholderSearch,
        })

        setFilterValues(prev => ({
            ...prev,
            catalog_id: catalogId,
            store_id: storeId,
            pricelist_id: pricelistId,
            category_id: categoryId,
            category_type: categoryType,
            input: input
        }))
        setFilterRows(filtersTmp)
    }
    const initOptions = () => {
        let optionsTmp = []
        let secondaryOtionsTmp = []

        if (settings === null || settings.allowedProductCreation === 1) {
            optionsTmp.push({
                "class": "add",
                "title": "Créer " + placeholderSearch,
                "action": "",
                "link": "/" + item + "/new"
            })

            secondaryOtionsTmp.push({
                "class": "blue addMany",
                "title": "Ajouter plusieurs " + titleNbItems,
                "action": "handleAddMany",
                "link": ""
            })
        }

        setOptions(optionsTmp)
        setSecondaryOptions(secondaryOtionsTmp)
    }
    const handleGetProducts = (list, error, pagination, status) => {
        if(error) {
            console.log(error);
        }

        setLoading(false);
        setList(list);
        setPagination(pagination !== undefined ? pagination : null );
    }
    const getProducts = (pCatalog = null, pCategoryId= 0, pCategoryType = "", pTextInput = "", pPage = 1, pPerPage = 25, pSortingName = "", pSortingValue = "") => {
        let controller
        let paramCatalog = pCatalog !== null ? pCatalog : filterValues.catalog_id
        let paramCategoryId = pCategoryId !== 0 ? pCategoryId : categoryId
        let paramCategoryType = pCategoryType !== "" ? pCategoryType : categoryType
        let paramInput = pTextInput !== "" ? pTextInput : input
        let paramPage = pPage !== 1 ? pPage : pageSelect
        let paramPerPage = pPerPage !== 25 ? pPerPage : perPage
        let paramSortingName = pSortingName !== "" ? pSortingName : sortingName
        let paramSortingValue = pSortingValue !== "" ? pSortingValue : sortingValue

        setLoading(true)

        switch (paramCategoryType) {
            case "category":
                controller = new CategoryController()
                controller._callback = handleGetProducts
                controller.indexProducts(paramCatalog, paramCategoryId, paramInput, paramPage, paramPerPage, paramSortingName, paramSortingValue)
                break
            case "subcategory":
                controller = new SubCategoryController()
                controller._callback = handleGetProducts
                controller.indexProducts(paramCatalog, paramCategoryId, paramInput, paramPage, paramPerPage, paramSortingName, paramSortingValue)
                break
            default:
                controller = new ProductController()
                controller._callback = handleGetProducts
                controller.index(paramCatalog, paramInput, paramPage, paramPerPage, paramSortingName, paramSortingValue)
                break
        }
    }
    const getCatalogs = () => {
        const controller = new CatalogController()
        controller._callback = handleGetCatalogs
        controller.index("", 0, 0, true, "name", "asc")
    }
    const handleGetCatalogs = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                localStorage.setItem("catalogs", JSON.stringify(list))

                if (list.length === 1)
                    localStorage.setItem("catalog", JSON.stringify(list[0]))
                else
                    localStorage.setItem("catalog", JSON.stringify({}))

                let listTmp = [];

                for (let i in list) {
                    listTmp.push({
                        value: list[i].name,
                        id: list[i].id,
                        object: list[i]
                    })
                }

                setCatalogs(listTmp)

                if (list.length > 0 && catalogId === null) {
                    handleChange("catalog_id", "int", list[0].id);
                }

                break
            default: break
        }
    }
    const getCategories = () => {
        if (catalogId !== null) {
            const controller = new CategoryController()
            controller._callback = handleGetCategories
            controller.index(catalogId, "", 0, 0, true)
        }
        else {
            setCategories([
                {
                    value: "Toutes les catégories",
                    id: null,
                    type: ""
                },
                {
                    value: "----------",
                    id: null,
                    type: ""
                }
            ])
        }
    }
    const handleGetCategories = (list, error) => {
        if (error) {
            console.log(error)
            return
        }

        let categoriesTmp = [
            {
                value: "Toutes les catégories",
                id: null,
                type: ""
            }, {
                value: "----------",
                id: null,
                type: ""
            }
        ]
        let i, j

        for (i in list) {
            categoriesTmp.push({
                value: list[i].name,
                id: list[i].id,
                type: "category"
            })

            for (j in list[i].subCategories)
                categoriesTmp.push({
                    value: "--- " + list[i].subCategories[j].name,
                    id: list[i].subCategories[j].id,
                    type: "subcategory"
                })
        }

        setCategories(categoriesTmp)
    }
    const getPricelists = () => {
        const controller = new PricelistController()
        controller._callback = handleGetPricelists
        controller.index(catalogId, "", 0, 0, true)
    }
    const handleGetPricelists = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                const catalogs = JSON.parse(localStorage.getItem("catalogs"))
                let listTmp = []
                let name
                let mainPricelist = null

                for (let i in list) {
                    if (catalogId === null)
                        name = "(" + catalogs[catalogs.findIndex(_ => _.id === list[i].catalog_id)].name + ") " + list[i].name
                    else
                        name = list[i].name

                    listTmp.push({
                        value: name,
                        id: list[i].id,
                        object: list[i]
                    })

                    if (mainPricelist === null && list[i].main === 1)
                        mainPricelist = list[i]
                }

                setPricelists(listTmp)

                if (mainPricelist !== null)
                    setPricelistId(mainPricelist.id)

                break

            default: break
        }
    }
    const getVats = () => {
        const controller = new VatRateController()
        controller._callback = handleGetVats
        controller.index()
    }
    const handleGetVats = (list, error, status) => {
        switch (status) {
            case 200:
                let listTmp = []

                for (let i in list) {
                    listTmp.push({
                        value: list[i].name,
                        id: list[i].id,
                        object: list[i]
                    })
                }

                setVatRates(listTmp)

                break

            default: break
        }
    }
    const updateFilters = () => {
        if (filterRows.length === 0) return
        let filtersTmp = filterRows.slice()

        if (filtersTmp.findIndex(_ => _.attribute === "catalog_id") >= 0)
            filtersTmp[filtersTmp.findIndex(_ => _.attribute === "catalog_id")].list = catalogs

        if (filtersTmp.findIndex(_ => _.attribute === "store_id") >= 0)
            filtersTmp[filtersTmp.findIndex(_ => _.attribute === "store_id")].list = stores

        filtersTmp[filtersTmp.findIndex(_ => _.attribute === "category_id")].list = categories

        setFilterRows(filtersTmp)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        const obj = FormBuilder.handleChange(filterRows, setFilterValues, attribute, returnType, val, strict)

        switch (attribute) {
            case "catalog_id":
                setCatalogId(obj.value)
                break
            case "store_id":
                setStoreId(obj.value)
                break
            case "pricelist_id":
                setPricelistId(obj.value)
                break
            case "category_id":
                setCategoryId(obj.value)
                setCategoryType(obj.type)
                break
            case "input":
                setInput(obj.value)
                break
            default: break
        }
    }
    const handleChangePriceable = val => {
        setPricelistId(parseInt(val))
    }
    const updatePageSelect = page => {
        setPageSelect(page)
    }
    const handleRefresh = (force = true) => {
        if (!force) {
            if (perPage !== prevPerPage || catalogId !== prevCatalogId || input !== prevInput) {
                if (pageSelect !== 1) {
                    setPageSelect(1)
                    return
                }
            }
        }

        getProducts(filterValues.catalog_id, filterValues.category_id, filterValues.category_type, filterValues.input, pageSelect, perPage, sortingName, sortingValue);
    }
    const handleUpdate = object => {
        let index = list.findIndex(item => item.id === object.id)
        if (index < 0) return

        let listTmp = list.slice()
        let keys = Object.keys(listTmp[index])
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (object[key] !== undefined)
                listTmp[index][key] = object[key]
        }

        setList(listTmp)
    }
    const handleRemove = () => {
        handleRefresh()
    }

    useEffect(() => {
        document.title = "Back office - " + titleWindow

        initStores()
        getCatalogs()
        getVats()
        updateModel()
        initFilters()
        initOptions()
    }, [])
    useEffect(() => {
        updateFilters();
    }, [catalogs, categories, pricelists, stores])
    useEffect(() => {
        updateModel();
    }, [categories])
    useEffect(() => {
        if (catalogId !== prevCatalogId || categoryType !== prevCategoryType || categoryId !== prevCategoryId) {
            updateModel()
        }

        if (catalogId !== prevCatalogId) {
            getPricelists()
            getCategories()
        }

        if (vatRates.length > 0)
            handleRefresh(false)
    }, [perPage, catalogId, categoryType, categoryId, pageSelect, sortingName, sortingValue, vatRates])
    useEffect(() => {
        if (prevInput === undefined) return

        const timeoutInput = setTimeout(() => {
            handleRefresh(false)
        }, 1000)

        return () => clearTimeout(timeoutInput)
    }, [input])

    return(
        <ListingContext.Provider value={{page: pageSelect, vatRates}}>
            <Listing
                item={ item }
                itemClass={ itemClass }
                placeholderSearch={ placeholderSearch }
                titleNbItems={ titleNbItems }
                emptyList={ emptyList }
                model={ model }
                options={ options }
                secondaryOptions={ secondaryOptions }
                filters={ filterRows }
                filterValues={ filterValues }
                changeFilters={ handleChange }
                activeHistory={ activeHistory }
                page={ page }
                pageSelect={ pageSelect }
                updatePageSelect={ updatePageSelect }
                list={ list }
                loading={ loading }
                pagination={ pagination }
                priceable={ pricelists.length > 0 ? pricelists : null }
                priceableValue={ pricelistId }
                storeableValue={ storeId }
                priceableChange={ handleChangePriceable }
                checkable={ false }
                openable={ true }
                setPerPage={ setPerPage }
                sortingName={ sortingName }
                sortingValue={ sortingValue }
                setSortingName={ setSortingName }
                setSortingValue={ setSortingValue } />
            <Switch>
                <Route exact path={ "/products/new" }>
                    <NewObject handleIndex={ handleRefresh } />
                </Route>
                <Route exact path={ "/catalogs/:idCatalog/products/:id/(information|pricelists|barcodes|stats)" }>
                    <ObjectSheet
                        objectType={ itemClass }
                        previousLink={ item }
                        textRemoveButton={ textRemoveButton }
                        handleUpdate={ handleUpdate }
                        handleRemove={ handleRemove } />
                </Route>
            </Switch>
        </ListingContext.Provider>
    )
}

export default Products;
