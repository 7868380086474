import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import usePrevious from "../../../class/tool/usePrevious";
import LoaderCircle from "../../loader/LoaderCircle";
import FormBuilder from "../../../class/tool/FormBuilder";
import CatalogController from "../../../stories/_catalog/Catalogs/CatalogController";
import CategoryController from "../../../stories/_catalog/Categories/CategoryController";
import ProductController from "../../../stories/_catalog/Products/ProductController";
import VatRateController from "../../../stories/_catalog/VatRates/VatRateController";
import '../../../css/form/Form.css';

const FormStart = props => {
    const item = "products";
    const controller = new ProductController();
    const env = JSON.parse(localStorage.getItem("env"));
    const defaultCatalog = JSON.parse(localStorage.getItem("catalog"));
    const queryParams = new URLSearchParams(window.location.search);

    const { object, handleIndex } = props;
    const [ reload, setReload ] = useState(false);
    const [ loadingCatalogs, setLoadingCatalogs ] = useState(true);
    const [ loadingCategories, setLoadingCategories ] = useState(false);
    const [ loadingVatRates, setLoadingVatRates ] = useState(true);
    const [ catalogId, setCatalogId ] = useState(queryParams.get("catalog") !== null ? queryParams.get("catalog") : (Object.keys(defaultCatalog).length > 0 ? defaultCatalog.id : null));
    const [ catalogs, setCatalogs ] = useState([
        {
            value: "Choisir un catalogue",
            id: null
        }, {
            value: "----------",
            id: null
        }
    ]);
    const [ categories, setCategories ] = useState([
        {
            value: "Choisir une catégorie",
            id: null,
            type: ""
        }, {
            value: "----------",
            id: null,
            type: ""
        }
    ]);
    const [ vatRates, setVatRates ] = useState([
        {
            value: "Choisir une TVA",
            id: null
        }, {
            value: "----------",
            id: null
        }
    ]);
    const [ variablePrice, setVariablePrice ] = useState(false);
    const [ values, setValues ] = useState({});
    const [ errors, setErrors ] = useState([]);
    const [ saving, setSaving ] = useState(false);
    const [ globalError, setGlobalError ] = useState(null);
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun"
        },
        {
            label: "Catégorie",
            attribute: "category_id",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        },
        {
            label: "TVA",
            attribute: "vat_rate_id",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        },
        {
            label: "Prix",
            attribute: "price",
            inputType: "text",
            returnType: "float",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Prix",
            emptyText: "Aucune",
            variablePrice: variablePrice
        },
        {
            label: "Réference",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Réference",
            emptyText: "Aucune"
        }
    ]);
    const prevCatalogId = usePrevious(catalogId);

    const getCatalogs = () => {
        const controller = new CatalogController()
        controller._callback = handleGetCatalogs
        controller.index("", 0, 0, true, "name", "asc")
    }
    const handleGetCatalogs = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                localStorage.setItem("catalogs", JSON.stringify(list))

                if (list.length === 1)
                    localStorage.setItem("catalog", JSON.stringify(list[0]))
                else
                    localStorage.setItem("catalog", JSON.stringify({}))

                let listTmp = catalogs.slice()

                for (let i in list) {
                    listTmp.push({
                        value: list[i].name,
                        id: list[i].id,
                        readonly: list[i].planned_deployments_to.length > 0
                    })
                }

                setCatalogs(listTmp)

                if (list.length > 0 && catalogId === null) {
                    handleChange("catalog_id", "int", list[0].id);
                }

                break
            default:
                setGlobalError("Impossible de récupérer les catalogues")
                break
        }

        setLoadingCatalogs(false)
    }
    const getCategories = () => {
        if (catalogId !== null) {
            setLoadingCategories(true)

            const controller = new CategoryController()
            controller._callback = handleGetCategories
            controller.index(catalogId, "", 0, 0, true)
        }
        else {
            setCategories([
                {
                    value: "Choisir une catégorie",
                    id: null
                }, {
                    value: "----------",
                    id: null
                }
            ])
            setValues(prev => ({
                ...prev,
                category_id: null,
                vat_rate_id: null
            }))
        }
    }
    const handleGetCategories = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                let categoriesTmp = [
                    {
                        value: "Choisir une catégorie",
                        id: null,
                        type: ""
                    }, {
                        value: "----------",
                        id: null,
                        type: ""
                    }
                ]

                for (let i in list) {
                    categoriesTmp.push({
                        value: list[i].name,
                        id: list[i].id,
                        type: "categories",
                        object: list[i]
                    });

                    for (let j in list[i].subCategories) {
                        categoriesTmp.push({
                            value: "--- " + list[i].subCategories[j].name,
                            id: list[i].subCategories[j].id,
                            type: "subcategories",
                            object: list[i].subCategories[j]
                        });
                    }
                }

                setCategories(categoriesTmp)
                break
            default:
                setGlobalError("Impossible de récupérer les catégories")
                break
        }

        setLoadingCategories(false)
    }
    const getVatRates = () => {
        const controller = new VatRateController()
        controller._callback = handleGetVatRates
        controller.index()
    }
    const handleGetVatRates = (list, error, status) => {
        switch (status) {
            case 200:
                let vatRatesTmp = [
                    {
                        value: "Choisir une TVA",
                        id: null
                    }, {
                        value: "----------",
                        id: null
                    }
                ]

                for (let item in list) {
                    vatRatesTmp.push({
                        value: list[item].name,
                        id: list[item].id
                    })
                }

                setVatRates(vatRatesTmp)
                break
            default:
                setGlobalError("Impossible de récupérer les TVA")
                break
        }

        setLoadingVatRates(false)
    }
    const initValues = () => {
        controller.setFormValues(object, null, null, null, setValues, true)
    }
    const refreshRows = () => {
        let rowsTmp = rows.slice()

        if (catalogs.length > 3) {
            if (!rowsTmp.find(_ => _.attribute === "catalog_id")) {
                rowsTmp.splice(1, 0, {
                    label: "Catalogue",
                    attribute: "catalog_id",
                    inputType: "select",
                    returnType: "int",
                    list: catalogs,
                    dictionary: null,
                    classnameLabel: "label",
                    classnameInput: "",
                    classnameNoInput: "",
                    placeholder: "",
                    emptyText: "Aucun"
                })
            }
            else
                rowsTmp[rowsTmp.findIndex(_ => _.attribute === "catalog_id")].list = catalogs
        }

        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "category_id")].list = categories
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "category_id")].loading = loadingCategories
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "vat_rate_id")].list = vatRates
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "price")].variablePrice = variablePrice

        setRows(rowsTmp)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        const obj = FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict)

        switch (attribute) {
            case "variablePrice":
                setVariablePrice(obj.value)

                if (obj.value)
                    FormBuilder.handleChange(rows, setValues, "price", "int", null, true);

                break
            case "catalog_id":
                setCatalogId(obj.value)
                break
            case "category_id":
                if (obj.value === null)
                    FormBuilder.handleChange(rows, setValues, "vat_rate_id", "int", null, true)
                else {
                    if (obj.filtered.length > 0 && obj.filtered[0].list.length > 0 && obj.filtered[0].list[obj.index].object !== undefined) {
                        if (obj.type === "categories") {
                            if (obj.filtered[0].list[obj.index].object.vat !== undefined && obj.filtered[0].list[obj.index].object.vat !== null)
                                FormBuilder.handleChange(rows, setValues, "vat_rate_id", "int", obj.filtered[0].list[obj.index].object.vat.id, true)
                        }
                        else if (obj.type === "subcategories") {
                            if (obj.filtered[0].list[obj.index].object.vat_rate_id !== undefined && obj.filtered[0].list[obj.index].object.vat_rate_id !== null)
                                FormBuilder.handleChange(rows, setValues, "vat_rate_id", "int", obj.filtered[0].list[obj.index].object.vat_rate_id, true)
                        }
                    }
                }

                break
            default: break
        }
    }
    const callToSave = () => {
        setGlobalError(null)
        reinitAllEdits()
        save()
    }
    const reinitAllEdits = () => {
        setErrors([])
    }
    const returnUpdates = () => {
        return controller.returnUpdatesFromCompare(object, values)
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides")

        if(errorDatas !== undefined) {
            let keys = Object.keys(errorDatas)
            let fields = ["reference"]

            for(let item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false)
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice()

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom"
                else errorsTmp["name"] = "Ce nom n'est pas valide"
                break
            default: break
        }

        setErrors(errorsTmp)
    }
    const save = () => {
        const datas = returnUpdates()

        if(Object.keys(datas).length === 0) return

        if (!datas.variablePrice && datas.price === undefined) {
            setGlobalError("Vous devez saisir un prix")
            return
        }

        const catalogs = JSON.parse(localStorage.getItem("catalogs"))
        const catalog = catalogs.find(_ => _.id === datas.catalog_id)

        datas.vats = {}
        datas.prices = {}

        switch (env.type) {
            case "company":
                for (let i in catalog.stores) {
                    if (datas.vat_rate_id !== undefined && datas.vat_rate_id !== null)
                        datas.vats[env.stores.find(_ => _.shortName === catalog.stores[i].shortName).id] = datas.vat_rate_id

                    if (!datas.variablePrice)
                        datas.prices[env.stores.find(_ => _.shortName === catalog.stores[i].shortName).id] = datas.price
                }

                break
            case "store":
                datas.vats[env.id] = datas.vat_rate_id

                if (!datas.variablePrice)
                    datas.prices[env.id] = datas.price

                break
            default: break
        }

        delete datas.price
        delete datas.vat_rate_id

        if (datas.variablePrice)
            delete datas.prices

        setSaving(true)

        controller._callback = handleReturnSave
        controller.post(Object.keys(defaultCatalog).length > 0 ? defaultCatalog.id : values.catalog_id, datas)
    }
    const handleReturnSave = (response, error, status) => {
        setSaving(false)

        switch (status) {
            case 201:
                if (values.catalog_id !== undefined && values.catalog_id !== null) // Enregistre pour les futurs création la pré-sélection du catalogue
                    localStorage.setItem("defaultCatalogIdInForm", values.catalog_id)

                setReload(true)
                handleIndex()
                break
            case 422:
                check422Errors(error)
                break
            default:
                setGlobalError("Une erreur s'est produite lors de l'enregistrement de la catégorie")
                break
        }
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()
        callToSave()
    }

    useEffect(() => {
        if (Object.keys(defaultCatalog).length === 0)
            getCatalogs()
        else {
            setLoadingCatalogs(false)
            getCategories()
        }

        getVatRates()
        initValues()
    }, [])
    useEffect(() => {
        refreshRows()
    }, [loadingCatalogs, loadingVatRates, loadingCategories, catalogs, categories, vatRates, variablePrice])
    useEffect(() => {
        if (prevCatalogId !== undefined && catalogId !== prevCatalogId)
            getCategories()
    }, [catalogId])

    return (
        <div className="clearing">
            {
                reload && <Redirect to={ "/" + item } />
            }
            {
                loadingCatalogs || loadingVatRates
                    ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5"/>
                    : <form className="form" onSubmit={handleSubmit}>
                        {
                            globalError !== null && <p className="globalError">{globalError}</p>
                        }
                        {
                            rows.map((row, index) => (
                                <div key={ index } className="clearing">
                                    {
                                        row.noLabel === undefined
                                        && <label className={ row.classnameLabel }>{ row.label }</label>
                                    }
                                    {
                                        FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                                    }
                                </div>
                            ))
                        }
                        <button className={"submit " + (saving ? "hide" : "")}>
                            {
                                saving
                                    ? "Enregistrement..."
                                    : "Enregistrer"
                            }
                            <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <Link to={ "/" + item }>
                            <button className="cancel align">Fermer</button>
                        </Link>
                        <div className="clearing" />
                    </form>
            }
        </div>
    )
}

export default FormStart;
