import React, { useEffect, useState } from "react";
import VatRateController from "../../../stories/_catalog/VatRates/VatRateController";
import LoaderCircle from "../../loader/LoaderCircle";
import FormBuilder from "../../../class/tool/FormBuilder";

const CategoryFormSheet = props => {
    const storeSettings = JSON.parse(localStorage.getItem("storeSettings"));
    const { object, values, errors, updated, setGlobalError, handleChange, handleSave } = props;
    const [ loadingVatRates, setLoadingVatRates ] = useState(true);
    const [ edit, setEdit ] = useState("");
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "Référence",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Référence",
            emptyText: "Aucune",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "TVA",
            attribute: "vat_rate_id",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "Couleur",
            attribute: "color",
            inputType: "color",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        }
    ]);

    const handleGetVatRates = (list, error) => {
        if(error) {
            setGlobalError("Impossible de récupérer les TVA");
        }

        let rowsTmp = rows.slice();
        let vatRates = [
            {
                value: "Choisir une TVA",
                id: null
            }, {
                value: "----------",
                id: null
            }
        ];

        for (let item in list)
            vatRates.push({
                value: list[item].name,
                id: list[item].id
            });

        rowsTmp[2].list = vatRates;
        setRows(rowsTmp);
        setLoadingVatRates(false);
    }
    const getVatRates = () => {
        const controller = new VatRateController();
        controller._callback = handleGetVatRates;
        controller.index();
    }
    const buildRow = row => {
        if (edit === row.attribute || (["color"].includes(row.inputType) && row.edit === undefined))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit);
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit);
    }
    const handleEdit = attribute => {
        setEdit(attribute);
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict);
    }
    const reinitEdit = () => {
        setEdit("");
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        handleSave();
    }

    useEffect(() => {
        getVatRates()
    }, []);

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit } >
            {
                loadingVatRates
                ? <div className="wrapCenterLoader">
                    <LoaderCircle display="loader restGETInForm" strokeWidth="8" />
                </div>
                : <div className="clearing">
                    {
                        rows.map((row, index) => (
                            <div key={ index } className="clearing">
                                <label className={ row.classnameLabel }>{ row.label }</label>
                                {
                                    buildRow(row)
                                }
                            </div>
                        ))
                    }
                </div>
            }
        </form>
    )
}

export default CategoryFormSheet;
