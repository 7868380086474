import React, {useContext, useEffect, useState} from "react";
import { Redirect, useParams, useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import $ from "jquery";
import FormBuilder from "../../class/tool/FormBuilder";
import LoaderCircle from "../loader/LoaderCircle";
import CategoryHeaderSheet from "../form/category/CategoryHeaderSheet";
import CategoryFormSheet from "../form/category/CategoryFormSheet";
import ProductHeaderSheet from "../form/product/ProductHeaderSheet";
import ProductFormSheet from "../form/product/ProductFormSheet";
import SubCategoryHeaderSheet from "../form/subcategory/SubCategoryHeaderSheet";
import SubCategoryFormSheet from "../form/subcategory/SubCategoryFormSheet";
import SellerGroupHeaderSheet from "../form/sellergroup/SellerGroupHeaderSheet";
import SellerGroupFormSheet from "../form/sellergroup/SellerGroupFormSheet";
import SellerHeaderSheet from "../form/seller/SellerHeaderSheet";
import SellerFormSheet from "../form/seller/SellerFormSheet";
import PricelistHeaderSheet from "../form/pricelist/PricelistHeaderSheet";
import PricelistFormSheet from "../form/pricelist/PricelistFormSheet";
import PaymentMethodHeaderSheet from "../form/paymentmethod/PaymentMethodHeaderSheet";
import PaymentMethodFormSheet from "../form/paymentmethod/PaymentMethodFormSheet";
import PrinterHeaderSheet from "../form/printer/PrinterHeaderSheet";
import PrinterFormSheet from "../form/printer/PrinterFormSheet";
import PricelistCategoriesFormSheet from "../form/pricelist/PricelistCategoriesFormSheet";
import ProductPricelistsFormSheet from "../form/product/ProductPricelistsFormSheet";
import SellerGroupRulesFormSheet from "../form/sellergroup/SellerGroupRulesFormSheet";
import ProductBarcodesFormSheet from "../form/product/ProductBarcodesFormSheet";
import DisplayHeaderSheet from "../form/display/DisplayHeaderSheet";
import DisplayFormSheet from "../form/display/DisplayFormSheet";
import DisplaySettingsFormSheet from "../form/display/DisplaySettingsFormSheet";
import BarcodeReaderFormSheet from "../form/barcodereader/BarcodeReaderFormSheet";
import BarcodeReaderHeaderSheet from "../form/barcodereader/BarcodeReaderHeaderSheet";
import StoreHeaderSheet from "../form/parameter/StoreHeaderSheet";
import StoreSettingFormSheet from "../form/parameter/StoreSettingFormSheet";
import StoreHeaderFormSheet from "../form/parameter/StoreHeaderFormSheet";
import StoreReceiptFormSheet from "../form/parameter/StoreReceiptFormSheet";
import StoreClosingFormSheet from "../form/parameter/StoreClosingFormSheet";
import StoreBasketFormSheet from "../form/parameter/StoreBasketFormSheet";
import StoreGeneralFormSheet from "../form/parameter/StoreGeneralFormSheet";
import AccountMeHeaderSheet from "../form/account/AccountMeHeaderSheet";
import AccountMeFormSheet from "../form/account/AccountMeFormSheet";
import AccountHeaderSheet from "../form/account/AccountHeaderSheet";
import AccountFormSheet from "../form/account/AccountFormSheet";
import CatalogHeaderSheet from "../form/catalog/CatalogHeaderSheet";
import CatalogFormSheet from "../form/catalog/CatalogFormSheet";
import SecureBox from "../overbox/asking/SecureBox";
import StorePrefixFormSheet from "../form/parameter/StorePrefixFormSheet";
import SellerBarcodesFormSheet from "../form/seller/SellerBarcodesFormSheet";
import CategoryController from "../../stories/_catalog/Categories/CategoryController";
import SubCategoryController from "../../stories/_catalog/SubCategories/SubCategoryController";
import ProductController from "../../stories/_catalog/Products/ProductController";
import SellerGroupController from "../../stories/_setting/SellerGroups/SellerGroupController";
import SellerController from "../../stories/_setting/Sellers/SellerController";
import PricelistController from "../../stories/_catalog/Pricelists/PricelistController";
import PaymentMethodController from "../../stories/_setting/PaymentMethods/PaymentMethodController";
import PrinterController from "../../stories/_setting/Printers/PrinterController";
import VatRateController from "../../stories/_catalog/VatRates/VatRateController";
import VatController from "../../stories/_catalog/Vats/VatController";
import PriceVariationController from "../../stories/_catalog/PriceVariations/PriceVariationController";
import DisplayController from "../../stories/_setting/Displays/DisplayController";
import PriceController from "../../stories/_catalog/Prices/PriceController";
import StoreSettingController from "../../stories/_setting/StoreSettings/StoreSettingController";
import BarcodeReaderController from "../../stories/_setting/BarcodeReaders/BarcodeReaderController";
import PaymentTerminalController from "../../stories/_setting/PaymentTerminals/PaymentTerminalController";
import ActionController from "../../stories/_setting/Actions/ActionController";
import PredefinedPaymentMethodController from "../../stories/_setting/PredefinedPaymentMethods/PredefinedPaymentMethodController";
import AccountController from "../../stories/_account/Accounts/AccountController";
import StoreController from "../../stories/_account/Stores/StoreController";
import CatalogController from "../../stories/_catalog/Catalogs/CatalogController";
import PriceVariation from "../../stories/_catalog/PriceVariations/PriceVariation";
import Vat from "../../stories/_catalog/Vats/Vat";
import Price from "../../stories/_catalog/Prices/Price";
import VatRuleController from "../../stories/_catalog/VatRules/VatRuleController";
import VatRule from "../../stories/_catalog/VatRules/VatRule";
import PriceVariationRule from "../../stories/_catalog/PriceVariationRules/PriceVariationRule";
import PriceVariationRuleController from "../../stories/_catalog/PriceVariationRules/PriceVariationRuleController";
import SellerSettingController from "../../stories/_setting/SellerSettings/SellerSettingController";
import ListingContext from "../../context/ListingContext";
import BackofficeContext from "../../context/BackofficeContext";
import PaymentTerminalHeaderSheet from "../form/paymentterminal/PaymentTerminalHeaderSheet";
import PaymentTerminalFormSheet from "../form/paymentterminal/PaymentTerminalFormSheet";

const ObjectSheet = props => {
    const env = JSON.parse(localStorage.getItem("env"));
    const { objectType, existingObject, idStore, context, navigationItem, previousLink, previousAction, textRemoveButton, handleUpdate, handleRemove, closeDisplay } = props;
    const { savePage } = useContext(ListingContext);
    const { setErrorText, setValidText } = useContext(BackofficeContext);
    const [ closeSheet, setCloseSheet ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ loadingCategory, setLoadingCategory ] = useState(true);
    const [ loadingCategories, setLoadingCategories ] = useState(true);
    const [ loadingProducts, setLoadingProducts ] = useState(true);
    const [ loadingPricelists, setLoadingPricelists ] = useState(true);
    const [ loadingVatRates, setLoadingVatRates ] = useState(true);
    const [ loadingStoreSettings, setLoadingStoreSettings ] = useState(true);
    const [ loadingActions, setLoadingActions ] = useState(true);
    const [ loadingSellerGroups, setLoadingSellerGroups ] = useState(true);
    const [ loadingPredefinedPaymentMethods, setLoadingPredefinedPaymentMethods ] = useState(true);
    const [ category, setCategory ] = useState({});
    const [ categories, setCategories ] = useState([]);
    const [ products, setProducts ] = useState([]);
    const [ pricelists, setPricelists ] = useState([]);
    const [ vatrates, setVatrates ] = useState([]);
    const [ storeSettings, setStoreSettings ] = useState([]);
    const [ userActions, setUserActions ] = useState([]);
    const [ sellerGroups, setSellerGroups ] = useState([]);
    const [ predefinedPaymentMethods, setPredefinedPaymentMethods ] = useState([]);
    const [ object, setObject ] = useState(existingObject !== undefined ? existingObject : null);
    const [ globalError, setGlobalError ] = useState(null);
    const [ removePopup, setRemovePopup ] = useState(false);
    const [ removeSecurityBox, setRemoveSecurityBox ] = useState(null);
    const [ choiceCatalogRemove, setChoiceCatalogRemove ] = useState(false);
    const [ choiceCatalogRemoveInStore, setChoiceCatalogRemoveInStore ] = useState(false);
    const [ storeToRemove, setStoreToRemove ] = useState([]);
    const [ saving, setSaving ] = useState(false);
    const [ updated, setUpdated ] = useState(false);
    const [ moving, setMoving ] = useState(false);
    const [ deleting, setDeleting ] = useState(false);
    const [ values, setValues ] = useState({});
    const [ errors, setErrors ] = useState([]);
    const [ tabulateSelect, setTabulateSelect ] = useState("");
    const [ removeChoice, setRemoveChoice ] = useState(null);
    const [ categoryToMoved, setCategoryToMoved ] = useState(null);
    const [ moveWindow, setMoveWindow ] = useState(false);
    const paramsInURL = useParams();
    const urlParams = context !== undefined ? context : paramsInURL;
    const locationParams = useLocation();
    const history = useHistory();

    const getStoreByName = name => {
        const env = JSON.parse(localStorage.getItem("env"))

        for (let i in env.stores) {
            if (env.stores[i].shortName === name)
                return env.stores[i]
        }

        return null
    }
    const buildController = () => {
        switch (objectType) {
            case "me":
            case "account": return new AccountController()
            case "category": return new CategoryController()
            case "subcategory": return new SubCategoryController()
            case "product": return new ProductController()
            case "sellergroup": return new SellerGroupController()
            case "seller": return new SellerController()
            case "pricelist": return new PricelistController()
            case "paymentmethod": return new PaymentMethodController()
            case "printer": return new PrinterController()
            case "display": return new DisplayController()
            case "barcodereader": return new BarcodeReaderController()
            case "paymentterminal": return new PaymentTerminalController();
            case "setting": return new StoreSettingController()
            case "store": return new StoreController()
            case "catalog": return new CatalogController()
            default: return null
        }
    }
    const show = refresh => {
        const controller = buildController();

        if (!refresh)
            controller._callback = handleShow
        else
            controller._callback = handleRefresh

        switch (objectType) {
            case "me":
                controller.show()
                break
            case "category":
            case "subcategory":
            case "product":
            case "pricelist":
                controller.show(urlParams.idCatalog, urlParams.id)
                break
            case "sellergroup":
            case "seller":
            case "paymentmethod":
            case "printer":
            case "display":
            case "barcodereader":
            case "paymentterminal":
            case "catalog":
                controller.show(urlParams.id)
                break
            case "setting":
                controller.show(env.id)
                break
            case "store":
                controller.show(env.id)
                break
            default: break
        }
    }
    const handleShow = (object, error, status) => {
        switch (status) {
            case 200:
                setObject(object)

                if (objectType === "setting")
                    localStorage.setItem("storeSettings", JSON.stringify(object))

                break
            default:
                setGlobalError("Une erreur technique s'est produite")
                break
        }
    }
    const handleRefresh = (object, error, status) => {
        switch (status) {
            case 200:
                switch (objectType) {
                    case "setting":
                        localStorage.setItem("storeSettings", JSON.stringify(object))
                        break
                    case "pricelist":
                        setLoading(true)
                        setLoadingCategories(true)
                        setLoadingProducts(true)
                        setLoadingVatRates(true)
                        setLoadingPricelists(true)
                        break
                    default: break
                }

                setObject(object)

                if (handleUpdate !== undefined)
                    handleUpdate(object)

                break
            default:
                setGlobalError("Une erreur technique s'est produite")
                break
        }
    }
    const getCategory = () => {
        let controller;

        if (object.category_type === "categories")
            controller = new CategoryController();
        else
            controller = new SubCategoryController();

        controller._callback = handleGetCategory;
        controller.show(urlParams.idCatalog, object.category_id);
    }
    const handleGetCategory = (category, error, status) => {
        switch (status) {
            case 200:
                setCategory(category)
                break
            default:
                setGlobalError("Impossible de récupérer les catégories")
                break
        }

        setLoadingCategory(false)
    }
    const getPricelists = () => {
        const controller = new PricelistController();
        controller._callback = handleGetPricelists;
        controller.index(urlParams.idCatalog, "", 0);
    }
    const handleGetPricelists = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setPricelists(list)
                break
            default:
                setGlobalError("Impossible de récupérer les tarifs")
                break
        }

        setLoadingPricelists(false)
    }
    const getCategories = () => {
        setLoadingCategories(true)

        const controller = new CategoryController()
        controller._callback = handleGetCategories
        controller.index(urlParams.idCatalog, "", 0, 0, true, "name", "asc")
    }
    const handleGetCategories = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setCategories(list)
                break
            default:
                setGlobalError("Impossible de récupérer les catégories")
                break
        }

        setLoadingCategories(false)
    }
    const getProducts = () => {
        const controller = new ProductController()
        controller._callback = handleGetProducts
        controller.index(urlParams.idCatalog, "", 0, 0, "name", "asc", true)
    }
    const handleGetProducts = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setProducts(list)
                break
            default:
                setGlobalError("Impossible de récupérer les produits")
                break
        }

        setLoadingProducts(false)
    }
    const getVatRates = () => {
        const controller = new VatRateController()
        controller._callback = handleGetVatRates
        controller.index()
    }
    const handleGetVatRates = (list, error, status) => {
        switch (status) {
            case 200:
                let vatRatesTmp = []

                for (let i in list) {
                    vatRatesTmp.push({
                        value: list[i].name,
                        id: list[i].id,
                        object: list[i]
                    })
                }

                setVatrates(vatRatesTmp)
                break
            default:
                setGlobalError("Impossible de récupérer les TVA")
                break
        }

        setLoadingVatRates(false)
    }
    const getStoreSettings = storeId => {
        let controller = new StoreSettingController()
        controller._callback = handleGetStoreSettings
        controller.show(storeId)
    }
    const handleGetStoreSettings = (object, error, status) => {
        switch (status) {
            case 200:
                setStoreSettings(object)
                break
            default:
                setGlobalError("Impossible de récupérer les réglages de la boutique")
                break
        }

        setLoadingStoreSettings(false)
    }
    const getActions = () => {
        const controller = new ActionController()
        controller._callback = handleGetActions
        controller.index("function&menu&pasteis&withunkeyboardable&rulable=true", false)
    }
    const handleGetActions = (list, error, status) => {
        switch (status) {
            case 200:
                let actions = []

                for (let item in list.sort((a,b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0))) {
                    actions.push({
                        value: list[item].label,
                        id: list[item].code
                    })
                }

                setUserActions(actions)
                break
            default:
                setGlobalError("Impossible de récupérer les actions")
                break
        }

        setLoadingActions(false)
    }
    const getSellerGroups = () => {
        const controller = new SellerGroupController()
        controller._callback = handleGetSellerGroups
        controller.index("", 0, 0, false)
    }
    const handleGetSellerGroups = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                let groups = [
                    {
                        value: "Choisir un groupe",
                        id: null
                    }, {
                        value: "----------",
                        id: null
                    }
                ]

                for (let i in list) {
                    groups.push({
                        value: list[i].name,
                        id: list[i].id,
                        object: list[i]
                    })
                }

                setSellerGroups(groups)
                break
            default:
                setGlobalError("Impossible de récupérer les tarifs")
                break
        }

        setLoadingSellerGroups(false)
    }
    const getPredefinedPaymentMethods = () => {
        const controller = new PredefinedPaymentMethodController()
        controller._callback = handleGetPredefinedPaymentMethods
        controller.index()
    }
    const handleGetPredefinedPaymentMethods = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                let listPredefined = [
                    {
                        value: "Aucun",
                        id: null
                    }, {
                        value: "----------",
                        id: null
                    }
                ];

                for (let item in list) {
                    listPredefined.push({
                        value: list[item].name,
                        id: list[item].id,
                        object: list[item]
                    });
                }

                setPredefinedPaymentMethods(listPredefined)
                break
            default:
                setGlobalError("Impossible de récupérer les paiement prédéfinis")
                break
        }

        setLoadingPredefinedPaymentMethods(false)
    }
    const initValues = () => {
        const controller = buildController()

        switch (objectType) {
            case "product":
                controller.setFormValues(object, category, pricelists, vatrates, setValues)
                break
            case "pricelist":
                controller.setFormValues(object, categories, products, vatrates, setValues)
                break
            case "setting":
                controller.setFormValues(env.id, object, setValues)
                break
            case "seller":
                controller.setFormValues(object, setValues, false)
                break
            default:
                controller.setFormValues(object, setValues)
                break
        }
    }
    const handleAction = (action, datas = null) => {
        switch (action) {
            case "analyze":
                break
            case "remove":
                callToRemove()
                break
            case "soldout":
                soldout()
                break
            case "move":
                if (datas === null) {
                    setErrorText("Les données sont incorrects");
                    return;
                }

                move(datas);

                break
            default:
                setGlobalError("Cette action n'est pas gérée pour le moment")
                break
        }
    }
    const getTabulatesByType = () => {
        let tabulates = []
        let datas = {}

        switch (objectType) {
            case "me":
            case "account":
            case "category":
            case "subcategory":
            case "paymentmethod":
            case "printer":
            case "store":
            case "catalog":
                datas = { title: "Général"}
                if (navigationItem !== undefined) {
                    datas.action = () => { setTabulateSelect("") }
                    datas.flag = ""
                }
                else {
                    datas.link = "information"
                }
                tabulates.push(datas)

                break
            case "seller":
                datas = { title: "Général"}
                if (navigationItem !== undefined) {
                    datas.action = () => { setTabulateSelect("") }
                    datas.flag = ""
                }
                else {
                    datas.link = "information"
                }
                tabulates.push(datas)

                datas = { title: "Codes barres"}
                if (navigationItem !== undefined) {
                    datas.action = () => { setTabulateSelect("barcodes") }
                    datas.flag = "barcodes"
                }
                else {
                    datas.link = "barcodes"
                }
                tabulates.push(datas)
                break;
            case "product":
                datas = { title: "Général"}
                if (navigationItem !== undefined) {
                    datas.action = () => { setTabulateSelect("") }
                    datas.flag = ""
                }
                else {
                    datas.link = "information"
                }
                tabulates.push(datas)

                datas = { title: "Tarifs"}
                if (navigationItem !== undefined) {
                    datas.action = () => { setTabulateSelect("pricelists") }
                    datas.flag = "pricelists"
                }
                else {
                    datas.link = "pricelists"
                }
                tabulates.push(datas)

                datas = { title: "Codes barres"}
                if (navigationItem !== undefined) {
                    datas.action = () => { setTabulateSelect("barcodes") }
                    datas.flag = "barcodes"
                }
                else {
                    datas.link = "barcodes"
                }
                tabulates.push(datas)

                datas = { title: "Statistiques"}
                if (navigationItem !== undefined) {
                    datas.action = () => { setTabulateSelect("stats") }
                    datas.flag = "stats"
                }
                else {
                    datas.link = "stats"
                }
                tabulates.push(datas)
                break;
            case "pricelist":
                datas = { title: "Général"}
                if (navigationItem !== undefined) {
                    datas.action = () => { setTabulateSelect("") }
                    datas.flag = ""
                }
                else {
                    datas.link = "information"
                }
                tabulates.push(datas)

                datas = { title: "Catégories"}
                if (navigationItem !== undefined) {
                    datas.action = () => { setTabulateSelect("categories") }
                    datas.flag = "categories"
                }
                else {
                    datas.link = "categories"
                }
                tabulates.push(datas)

                if (object.main)
                    tabulates[1].readOnly = true

                break
            case "sellergroup":
                datas = { title: "Général"}
                if (navigationItem !== undefined) {
                    datas.action = () => { setTabulateSelect("") }
                    datas.flag = ""
                }
                else {
                    datas.link = "information"
                }
                tabulates.push(datas)

                datas = { title: "Droits"}
                if (navigationItem !== undefined) {
                    datas.action = () => { setTabulateSelect("rules") }
                    datas.flag = "rules"
                }
                else {
                    datas.link = "rules"
                }
                tabulates.push(datas)

                if (object.locked)
                    tabulates[1].readOnly = true

                break
            case "display":
                datas = { title: "Général"}
                if (navigationItem !== undefined) {
                    datas.action = () => { setTabulateSelect("") }
                    datas.flag = ""
                }
                else {
                    datas.link = "information"
                }
                tabulates.push(datas)

                datas = { title: "Réglages"}
                if (navigationItem !== undefined) {
                    datas.action = () => { setTabulateSelect("settings") }
                    datas.flag = "settings"
                }
                else {
                    datas.link = "settings"
                }
                tabulates.push(datas)

                break
            case "setting":
                tabulates.push({
                    action: () => { setTabulateSelect("") },
                    flag: "",
                    title: "Réglages"
                })
                tabulates.push({
                    action: () => { setTabulateSelect("basket") },
                    flag: "basket",
                    title: "Panier"
                })
                tabulates.push({
                    action: () => { setTabulateSelect("closing") },
                    flag: "closing",
                    title: "Comptabilité"
                })
                tabulates.push({
                    action: () => { setTabulateSelect("receipt") },
                    flag: "receipt",
                    title: "Impression"
                })
                tabulates.push({
                    action: () => { setTabulateSelect("header") },
                    flag: "header",
                    title: "En-tête et pied du ticket"
                })
                tabulates.push({
                    action: () => { setTabulateSelect("prefix") },
                    flag: "prefix",
                    title: "Préfixes"
                })
                break
            default: break
        }

        return tabulates
    }
    const buildTabulate = () => {
        let tabulates = getTabulatesByType()

        if (tabulates.length > 1) {
            return <nav className="tabulate">
                {
                    tabulates.map((tab, index) => writeTab(tab, index))
                }
            </nav>
        }
    }
    const writeTab = (tab, index) => {
        if (tab.readOnly) {
            return <p className="tab readonly">
                { tab.title }
            </p>
        }
        else {
            if (tab.link !== undefined) {
                return <Link key={ index } to={ locationParams.pathname.split('/').slice(0, -1).join('/') + "/" + tab.link }>
                    <p className={ "tab" + (locationParams.pathname.includes(tab.link) ? " select" : "") }>
                        { tab.title }
                    </p>
                </Link>
            }
            else if (tab.action !== undefined) {
                return <p key={ index } className={ "tab" + (tabulateSelect === tab.flag ? " select" : "") } onClick={ tab.action }>
                    { tab.title }
                </p>
            }
        }
    }
    const buildHeaderSheet = () => {
        switch (objectType) {
            case "me":
                return <AccountMeHeaderSheet
                    values={ values }
                    errors={ errors }
                    setSaving={ setSaving }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save }
                    handleAction={ handleAction } />
            case "account":
                return <AccountHeaderSheet
                    values={ values }
                    errors={ errors }
                    setSaving={ setSaving }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save }
                    handleAction={ handleAction } />
            case "catalog":
                return <CatalogHeaderSheet
                    object={ object }
                    values={ values }
                    errors={ errors }
                    setSaving={ setSaving }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save }
                    handleAction={ handleAction } />
            case "pricelist":
                return <PricelistHeaderSheet
                    object={ object }
                    values={ values }
                    errors={ errors }
                    setSaving={ setSaving }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save }
                    handleAction={ handleAction } />
            case "category":
                return <CategoryHeaderSheet object={ object } values={ values } handleAction={ handleAction } />
            case "subcategory":
                return <SubCategoryHeaderSheet object={ object } values={ values } moving={ moving } handleAction={ handleAction } />
            case "product":
                return <ProductHeaderSheet object={ object } values={ values } moving={ moving } handleAction={ handleAction } />
            case "sellergroup":
                return <SellerGroupHeaderSheet
                    values={ values }
                    errors={ errors }
                    setSaving={ setSaving }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save }
                    handleAction={ handleAction } />
            case "seller":
                return <SellerHeaderSheet
                    values={ values }
                    errors={ errors }
                    setSaving={ setSaving }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save }
                    handleAction={ handleAction } />
            case "paymentmethod":
                return <PaymentMethodHeaderSheet
                    values={ values }
                    errors={ errors }
                    setSaving={ setSaving }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save }
                    handleAction={ handleAction } />
            case "printer":
                return <PrinterHeaderSheet
                    values={ values }
                    errors={ errors }
                    setSaving={ setSaving }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save }
                    handleAction={ handleAction } />
            case "display":
                return <DisplayHeaderSheet
                    values={ values }
                    errors={ errors }
                    setSaving={ setSaving }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save }
                    handleAction={ handleAction } />
            case "barcodereader":
                return <BarcodeReaderHeaderSheet
                    values={ values }
                    errors={ errors }
                    setSaving={ setSaving }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save }
                    handleAction={ handleAction } />
            case "paymentterminal":
                return <PaymentTerminalHeaderSheet
                    values={ values }
                    errors={ errors }
                    setSaving={ setSaving }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save }
                    handleAction={ handleAction } />
            case "setting":
                return <div className="headerSheet">
                    <div className="infos">
                        <p className={"text"}>Réglages</p>
                    </div>
                </div>
            case "store":
                return <StoreHeaderSheet
                    values={values}
                    errors={errors}
                    setSaving={setSaving}
                    setGlobalError={setGlobalError}
                    handleChange={change}
                    handleSave={save}
                    handleAction={handleAction}/>
            default:
                break;
        }
    }
    const buildFormSheet = () => {
        switch (objectType) {
            case "me":
                return <AccountMeFormSheet
                    object={object}
                    values={ values }
                    errors={ errors }
                    updated={ updated }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save } />
            case "account":
                return <AccountFormSheet
                    object={ object }
                    values={ values }
                    errors={ errors }
                    updated={ updated }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save } />
            case "catalog":
                return <CatalogFormSheet
                    object={ object }
                    values={ values }
                    errors={ errors }
                    updated={ updated }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save } />
            case "pricelist":
                if (tabulateSelect === "categories" || locationParams.pathname.includes("categories")) {
                    return <PricelistCategoriesFormSheet
                        object={ object }
                        values={ values }
                        errors={ errors }
                        vatrates={ vatrates }
                        pricelists={ pricelists }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleAdd={ addPrice }
                        handleRemove={ removePrice }
                        handleRecovery={ recovery }
                        handleSave={ save } />
                }
                else {
                    return <PricelistFormSheet
                        object={ object }
                        values={ values }
                        errors={ errors }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save } />
                }
            case "category":
                return <CategoryFormSheet
                    object={ object }
                    values={ values }
                    errors={ errors }
                    updated={ updated }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save } />
            case "subcategory":
                return <SubCategoryFormSheet
                    object={ object }
                    values={ values }
                    errors={ errors }
                    updated={ updated }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save } />
            case "product":
                if (tabulateSelect === "pricelists" || locationParams.pathname.includes("pricelists")) {
                    return <ProductPricelistsFormSheet
                        object={ object }
                        values={ values }
                        errors={ errors }
                        pricelists={ pricelists }
                        vatrates={ vatrates }
                        category={ category }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleAdd={ addPrice }
                        handleRemove={ removePrice }
                        handleRecovery={ recovery }
                        handleSave={ save } />
                }
                else if (tabulateSelect === "barcodes" || locationParams.pathname.includes("barcodes")) {
                    return <ProductBarcodesFormSheet
                        object={ object }
                        values={ values }
                        storeSettings={ storeSettings }
                        errors={ errors }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save } />
                }
                else if (tabulateSelect === "stats" || locationParams.pathname.includes("stats")) {
                    return <></>
                }
                else {
                    return <ProductFormSheet
                        object={ object }
                        values={ values }
                        errors={ errors }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save } />
                }
            case "sellergroup":
                if (locationParams.pathname.includes("rules")) {
                    return <SellerGroupRulesFormSheet
                        values={ values }
                        errors={ errors }
                        userActions={ userActions }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save }
                    />
                }
                else {
                    return <SellerGroupFormSheet
                        values={ values }
                        errors={ errors }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save }
                    />
                }
            case "seller":
                if (tabulateSelect === "barcodes" || locationParams.pathname.includes("barcodes")) {
                    return <SellerBarcodesFormSheet
                        object={ object }
                        values={ values }
                        storeSettings={ storeSettings }
                        errors={ errors }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save } />
                }
                else {
                    return <SellerFormSheet
                        values={ values }
                        errors={ errors }
                        sellerGroups={ sellerGroups }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save } />
                }
            case "paymentmethod":
                return <PaymentMethodFormSheet
                    values={ values }
                    errors={ errors }
                    predefinedPaymentMethods={ predefinedPaymentMethods }
                    updated={ updated }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save } />
            case "printer":
                return <PrinterFormSheet
                    values={ values }
                    errors={ errors }
                    updated={ updated }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save } />
            case "display":
                if (locationParams.pathname.includes("settings")) {
                    return <DisplaySettingsFormSheet
                        values={ values }
                        errors={ errors }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save } />
                }
                else {
                    return <DisplayFormSheet
                        values={ values }
                        errors={ errors }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save } />
                }
            case "barcodereader":
                return <BarcodeReaderFormSheet
                    values={ values }
                    errors={ errors }
                    updated={ updated }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save } />
            case "paymentterminal":
                return <PaymentTerminalFormSheet
                    values={ values }
                    errors={ errors }
                    updated={ updated }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save } />
            case "setting":
                if (tabulateSelect === "basket") {
                    return <StoreBasketFormSheet
                        values={ values }
                        errors={ errors }
                        pricelists={ pricelists }
                        vatrates={ vatrates }
                        category={ category }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save } />
                }
                else if (tabulateSelect === "closing") {
                    return <StoreClosingFormSheet
                        values={ values }
                        storeSettings={ storeSettings }
                        errors={ errors }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save } />
                }
                else if (tabulateSelect === "receipt") {
                    return <StoreReceiptFormSheet
                        values={ values }
                        storeSettings={ storeSettings }
                        errors={ errors }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save } />
                }
                else if (tabulateSelect === "header") {
                    return <StoreHeaderFormSheet
                        values={ values }
                        storeSettings={ storeSettings }
                        errors={ errors }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save } />
                }
                else if (tabulateSelect === "prefix") {
                    return <StorePrefixFormSheet
                        values={ values }
                        storeSettings={ storeSettings }
                        errors={ errors }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save } />
                }
                else {
                    return <StoreSettingFormSheet
                        values={ values }
                        errors={ errors }
                        updated={ updated }
                        setGlobalError={ setGlobalError }
                        handleChange={ change }
                        handleSave={ save } />
                }
            case "store":
                return <StoreGeneralFormSheet
                    values={ values }
                    errors={ errors }
                    updated={ updated }
                    setGlobalError={ setGlobalError }
                    handleChange={ change }
                    handleSave={ save } />
            default: break;
        }
    }
    const callToRemove = () => {
        if (env.type === "company" && objectType === "catalog" && object.children.length > 0) {
            callMultiChoiceRemove()
        }
        else {
            setRemovePopup(!removePopup)
        }
    }
    const callMultiChoiceRemove = (removeStore = false) => {
        switch (objectType) {
            case "catalog":
                if (removeStore) {
                    setStoreToRemove(removeStore)
                    setChoiceCatalogRemoveInStore(true)
                }
                else
                    setChoiceCatalogRemove(true)

                break
            default: break
        }
    }
    const closeMultiChoiceRemove = () => {
        switch (objectType) {
            case "catalog":
                setChoiceCatalogRemove(false)
                setChoiceCatalogRemoveInStore(false)
                break
            default: break
        }
    }
    const choiceRemove = choice => {
        switch (objectType) {
            case "catalog":
                closeMultiChoiceRemove()
                setRemoveSecurityBox(choice)
                break
            default: break
        }
    }
    const closeSecurityBox = () => {
        setRemoveSecurityBox(null)
    }
    const initRemoveChoices = () => {
        if (removePopup) {
            switch (objectType) {
                case "category":
                case "subcategory":
                    setRemoveChoice(1);
                    break;
                default:
                    break;
            }
        }
        else {
            switch (objectType) {
                case "category":
                case "subcategory":
                    setRemoveChoice(null);
                    setCategoryToMoved(null);
                    break;
                default:
                    break;
            }
        }
    }
    const buildDescriptionRemove = () => {
        switch (objectType) {
            case "catalog":
                return <p className="warning">
                    Attention, l'ensemble des éléments qui compose ce catalogue vont être supprimés définitivement (catégories, produits, prix, etc.)
                    <br />
                    <br />
                    <strong>Cette action est irréversible.</strong>
                </p>;
            default:
                return <></>;
        }
    }
    const buildChoiceRemove = () => {
        switch (objectType) {
            case "category":
                return <div className={"listItemInOverbox"}>
                    <div className={"containerItem"}>
                        <p className={"item" + (removeChoice === 1 ? " actu red noSuffix" : "")} onClick={() => { setRemoveChoice(1); }}>Supprimer la catégorie avec tout son contenu</p>
                    </div>
                    <div className={"containerItem"}>
                        <p className={"item" + (removeChoice === 2 ? " actu red noSuffix" : "")} onClick={() => { setRemoveChoice(2); }}>Supprimer la catégorie et déplacer son contenu</p>
                    </div>
                </div>;
            case "subcategory":
                return <div className={"listItemInOverbox"}>
                    <div className={"containerItem"}>
                        <p className={"item" + (removeChoice === 1 ? " actu red noSuffix" : "")} onClick={() => { setRemoveChoice(1); }}>Supprimer la sous-catégorie et tout son contenu</p>
                    </div>
                    <div className={"containerItem"}>
                        <p className={"item" + (removeChoice === 2 ? " actu red noSuffix" : "")} onClick={() => { setRemoveChoice(2); }}>Supprimer la sous-catégorie et déplacer son contenu</p>
                    </div>
                </div>;
            default:
                return <></>;
        }
    }
    const remove = () => {
        const controller = buildController()

        switch (objectType) {
            case "catalog":
                if (removeSecurityBox === 1 || removeSecurityBox === 2) {
                    setDeleting(true)

                    if (removeSecurityBox === 1) {
                        for (let i in object.children) {
                            controller.detach(object.children[i].id)
                        }
                    }
                    else if (removeSecurityBox === 2) {
                        for (let i in object.children) {
                            controller.delete(object.children[i])
                        }
                    }

                    setTimeout(() => {
                        controller._callback = handleReturnRemove
                        controller.delete(object)
                    }, 5000)
                }
                else if (removeSecurityBox === 3 || removeSecurityBox === 4) {
                    if (removeSecurityBox === 3) {
                        for (let i in storeToRemove) {
                            controller.detach(storeToRemove[i].id)
                        }
                    }
                    else if (removeSecurityBox === 4) {
                        for (let i in storeToRemove) {
                            controller.delete(object.children.find(_ => _.owner_type === "stores" && _.owner_id === storeToRemove[i].id))
                        }
                    }

                    closeSecurityBox()
                    closeMultiChoiceRemove()
                    setStoreToRemove([])
                }
                else {
                    if (removeSecurityBox === null) {
                        setRemoveSecurityBox(5)
                    }
                    else {
                        setDeleting(true)

                        controller._callback = handleReturnRemove
                        controller.delete(object)
                    }
                }

                break
            case "category":
            case "subcategory":
                if (removeChoice === 2) {
                    setCategoryToMoved(null);
                    setMoveWindow(true);
                }
                else {
                    setDeleting(true);

                    controller._callback = handleReturnRemove;
                    controller.delete(object);
                }

                break
            default:
                setDeleting(true);

                controller._callback = handleReturnRemove;
                controller.delete(object);

                break
        }
    }
    const handleReturnRemove = (response, error, status) => {
        setDeleting(false)

        switch (status) {
            case 204:
                handleRemove()

                if (previousLink !== undefined) {
                    setCloseSheet(true)
                }
                else {
                    previousAction()
                }

                break
            default:
                setGlobalError("Impossible de supprimer " + textRemoveButton)
                break
        }
    }
    const soldout = () => {
        const controller = buildController()

        switch (objectType) {
            case "product":
                controller._callback = handleSoldout;
                controller.put(object.catalog_id, object, { soldOut: !object.soldOut });
                break;
            default: break;
        }
    }
    const handleSoldout = (response, error) => {
        if(error)
            setGlobalError("Impossible de soldout")
        else
            show(true)
    }
    const change = (rows, attribute, returnType, val, strict = false) => {
        let index

        switch (returnType) {
            case "pricelist":
                let split
                let targetObject
                let targetAttribute
                let lines
                let linesTmp
                let lineMainPricelist
                let idStore
                let idStoresToUpdate
                let mainVatId
                let mainPrice
                let defaultPriceVariationDatas
                let valueToSave

                switch (objectType) {
                    case "product":
                        split = attribute.split("|")
                        targetObject = parseInt(split[0])
                        targetAttribute = split[1]
                        lines = values.pricelist
                        linesTmp = values.pricelist.slice()
                        lineMainPricelist = lines.filter(line => line.pricelist.main === 1)[0]
                        idStore = parseInt(split[2])
                        idStoresToUpdate = getIdStoresToUpdate(idStore)

                        for (let i in idStoresToUpdate) {
                            idStore = idStoresToUpdate[i]

                            mainVatId = lineMainPricelist.productVatRate[idStore].id
                            mainPrice = lineMainPricelist.productPrice[idStore] !== null ? lineMainPricelist.productPrice[idStore].value : 0
                            defaultPriceVariationDatas = {
                                catalog_id: urlParams.idCatalog,
                                price_list_id: targetObject,
                                variable_id: object.id,
                                variable_type: "Product",
                                variationOperator: "=",
                                value: "0",
                                type: "DEVISE"
                            }

                            index = linesTmp.findIndex(obj => {
                                return obj.pricelist.id === targetObject
                            })

                            if (index < 0)  {
                                alert("Impossible de mettre à jour la donnée")
                                return
                            }

                            linesTmp[index].toUpdate[idStore] = true

                            switch (targetAttribute) {
                                case "variationOperator":
                                    if (linesTmp[index].productPriceVariation[idStore] === null)
                                        linesTmp[index].productPriceVariation[idStore] = new PriceVariation(defaultPriceVariationDatas);

                                    if (linesTmp[index].productPriceVariation[idStore].type === "PERCENT" && val === "=")
                                        return;

                                    linesTmp[index].productPriceVariation[idStore].rule_id = null;
                                    linesTmp[index].productPriceVariation[idStore].variationOperator = val;

                                    break;
                                case "variationValue":
                                    if (linesTmp[index].productPriceVariation[idStore] === null)
                                        linesTmp[index].productPriceVariation[idStore] = new PriceVariation(defaultPriceVariationDatas);

                                    if (val.length === 1 && (val.charAt(0) === "." || val.charAt(0) === ",")) val = "0" + val;
                                    if (val.length > 1 && val.charAt(0) === "0" && !val.includes(",") && !val.includes(".")) val = val.substring(1);

                                    valueToSave = FormBuilder.buildVal("string", val.replace(',', '.'));
                                    if (valueToSave.length > 0 && !/^(([^0]{1})([0-9])*|(0{1}))(\.\d{0,2})?$/gi.test(valueToSave)) return;

                                    linesTmp[index].productPriceVariation[idStore].rule_id = null;
                                    linesTmp[index].productPriceVariation[idStore].value = valueToSave;

                                    break;
                                case "variationType":
                                    if (linesTmp[index].productPriceVariation[idStore] === null)
                                        linesTmp[index].productPriceVariation[idStore] = new PriceVariation(defaultPriceVariationDatas);

                                    linesTmp[index].productPriceVariation[idStore].rule_id = null;
                                    linesTmp[index].productPriceVariation[idStore].type = val;

                                    if (val === "PERCENT" && linesTmp[index].productPriceVariation[idStore].variationOperator === "=")
                                        linesTmp[index].productPriceVariation[idStore].variationOperator = "-";

                                    break;
                                case "vatRate":
                                    if (linesTmp[index].productVat[idStore] === null)
                                        linesTmp[index].productVat[idStore] = new Vat({price_list_id: targetObject, applyable_id: object.id, applyable_type: "Product"});

                                    valueToSave = FormBuilder.buildVal("int", val);

                                    let vatRateId = null;

                                    for (let i = 0; i < vatrates.length; i++) {
                                        if (i === valueToSave) {
                                            vatRateId = vatrates[i].id;
                                        }
                                    }

                                    if (vatRateId === mainVatId)
                                        linesTmp[index].productVat[idStore] = null;
                                    else {
                                        linesTmp[index].productVat[idStore].vat_rule_id = null;
                                        linesTmp[index].productVat[idStore].vat_rate_id = vatRateId;
                                        // eslint-disable-next-line no-loop-func
                                        linesTmp[index].productVatRate[idStore] = vatrates.filter(item => item.id === linesTmp[index].productVat[idStore].vat_rate_id)[0];
                                    }

                                    break;
                                default:
                                    alert("Impossible de mettre à jour la donnée");
                                    break;
                            }

                            if (targetAttribute !== "vatRate" && linesTmp[index].productPrice[idStore] === null) {
                                linesTmp[index].productPrice[idStore] = new Price({
                                    value: 0,
                                    stringValue: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(0).replace(" €", ""),
                                    price_list_id: targetObject,
                                    priceable_id: object.id,
                                    priceable_type: "Product",
                                    variation_id: null,
                                    product_id : object.id
                                })
                            }

                            // Calcul all unit prices
                            for (let key in linesTmp) {
                                if (linesTmp[key].productPrice[idStore] === null) continue

                                valueToSave = 0

                                if (linesTmp[key].productPriceVariation[idStore] === null) {
                                    valueToSave = mainPrice
                                }
                                else {
                                    const basePrice = parseFloat(mainPrice)
                                    let variationValue = 0

                                    if (linesTmp[key].productPriceVariation[idStore].value !== undefined) {
                                        if (typeof linesTmp[key].productPriceVariation[idStore].value === "string")
                                            variationValue = FormBuilder.buildVal("float", linesTmp[key].productPriceVariation[idStore].value.replace(',', '.'))
                                        else
                                            variationValue = linesTmp[key].productPriceVariation[idStore].value
                                    }

                                    if (typeof variationValue === "string" && variationValue.length > 0)
                                        variationValue = parseFloat(variationValue.toString())

                                    switch (linesTmp[key].productPriceVariation[idStore].type) {
                                        case "DEVISE":
                                            switch (linesTmp[key].productPriceVariation[idStore].variationOperator) {
                                                case "=":
                                                    valueToSave = variationValue
                                                    break
                                                case "-":
                                                    valueToSave = basePrice - variationValue
                                                    break
                                                case "+":
                                                    valueToSave = basePrice + variationValue
                                                    break
                                                default:
                                                    valueToSave = basePrice
                                                    break
                                            }

                                            break;
                                        case "PERCENT":
                                            switch (linesTmp[key].productPriceVariation[idStore].variationOperator) {
                                                case "-":
                                                    valueToSave = basePrice * ((100 - variationValue) / 100)
                                                    break
                                                case "+":
                                                    valueToSave = basePrice * (1 + (variationValue / 100))
                                                    break
                                                default:
                                                    valueToSave = basePrice
                                                    break
                                            }

                                            break;
                                        default:
                                            valueToSave = basePrice
                                            break
                                    }
                                }

                                linesTmp[key].productPrice[idStore].value = valueToSave
                                linesTmp[key].productPrice[idStore].stringValue = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(valueToSave).replace(" €", "")
                            }
                        }

                        setValues(prev => ({
                            ...prev,
                            pricelist: linesTmp
                        }))

                        break
                    case "pricelist":
                        split = attribute.split("|")
                        targetObject = parseInt(split[0])
                        targetAttribute = split[1]
                        lines = values.categoriesLines
                        linesTmp = values.categoriesLines.slice()
                        idStore = parseInt(split[2])
                        idStoresToUpdate = getIdStoresToUpdate(idStore)

                        for (let i in idStoresToUpdate) {
                            idStore = idStoresToUpdate[i]

                            defaultPriceVariationDatas = {
                                store_id: idStore,
                                catalog_id: urlParams.idCatalog,
                                price_list_id: object.id,
                                rulable_id: targetObject,
                                rulable_type: "Category",
                                variationOperator: "-",
                                value: "0",
                                type: "DEVISE"
                            }

                            index = linesTmp.findIndex(object => {
                                return object.object.id === targetObject
                            })

                            if (index < 0)  {
                                alert("Impossible de mettre à jour la donnée")
                                return
                            }

                            linesTmp[index].toUpdate[idStore] = true
                            let indexRule

                            switch (targetAttribute) {
                                case "variationOperator":
                                    if (linesTmp[index].categoryPriceRule[idStore].find(_ => _.price_list_id === object.id) === undefined)
                                        linesTmp[index].categoryPriceRule[idStore].push(new PriceVariationRule(defaultPriceVariationDatas))

                                    indexRule = linesTmp[index].categoryPriceRule[idStore].findIndex(_ => _.price_list_id === object.id)

                                    if (linesTmp[index].categoryPriceRule[idStore][indexRule].type === "PERCENT" && val === "=")
                                        return

                                    linesTmp[index].categoryPriceRule[idStore][indexRule].rule_id = null
                                    linesTmp[index].categoryPriceRule[idStore][indexRule].variationOperator = val
                                    break
                                case "variationValue":
                                    if (linesTmp[index].categoryPriceRule[idStore].find(_ => _.price_list_id === object.id) === undefined)
                                        linesTmp[index].categoryPriceRule[idStore].push(new PriceVariationRule(defaultPriceVariationDatas))

                                    indexRule = linesTmp[index].categoryPriceRule[idStore].findIndex(_ => _.price_list_id === object.id)

                                    if (val.length === 1 && (val.charAt(0) === "." || val.charAt(0) === ",")) val = "0" + val
                                    if (val.length > 1 && val.charAt(0) === "0" && !val.includes(",") && !val.includes(".")) val = val.substring(1)

                                    valueToSave = FormBuilder.buildVal("string", val.replace(',', '.'))
                                    if (valueToSave.length > 0 && !/^(([^0]{1})([0-9])*|(0{1}))(\.\d{0,2})?$/gi.test(valueToSave)) return

                                    linesTmp[index].categoryPriceRule[idStore][indexRule].rule_id = null
                                    linesTmp[index].categoryPriceRule[idStore][indexRule].value = valueToSave
                                    break
                                case "variationType":
                                    if (linesTmp[index].categoryPriceRule[idStore].find(_ => _.price_list_id === object.id) === undefined)
                                        linesTmp[index].categoryPriceRule[idStore].push(new PriceVariationRule(defaultPriceVariationDatas))

                                    indexRule = linesTmp[index].categoryPriceRule[idStore].findIndex(_ => _.price_list_id === object.id)

                                    linesTmp[index].categoryPriceRule[idStore][indexRule].rule_id = null
                                    linesTmp[index].categoryPriceRule[idStore][indexRule].type = val

                                    if (val === "PERCENT" && linesTmp[index].categoryPriceRule[idStore][indexRule].variationOperator === "=")
                                        linesTmp[index].categoryPriceRule[idStore][indexRule].variationOperator = "-"

                                    break
                                case "vatRate":
                                    if (linesTmp[index].categoryVatRule[idStore].find(_ => _.price_list_id === object.id) === undefined)
                                        linesTmp[index].categoryVatRule[idStore].push(new VatRule({catalog_id: urlParams.idCatalog, price_list_id: object.id, rullable_id: targetObject, rullable_type: "Category"}))

                                    indexRule = linesTmp[index].categoryVatRule[idStore].findIndex(_ => _.price_list_id === object.id)
                                    mainVatId = linesTmp[index].categoryVatRule[idStore].find(_ => _.price_list_id === pricelists.find(_ => _.main === 1).id).vat_rate_id

                                    valueToSave = FormBuilder.buildVal("int", val)

                                    let vatRateId = null

                                    for (let i = 0; i < vatrates.length; i++) {
                                        if (i === valueToSave) {
                                            vatRateId = vatrates[i].id
                                        }
                                    }

                                    if (vatRateId === mainVatId)
                                        linesTmp[index].categoryVatRule[idStore].splice(indexRule, 1)
                                    else {
                                        linesTmp[index].categoryVatRule[idStore][indexRule].vat_rule_id = null
                                        linesTmp[index].categoryVatRule[idStore][indexRule].vat_rate_id = vatRateId
                                        // eslint-disable-next-line no-loop-func
                                        linesTmp[index].categoryVatRate[idStore][indexRule] = vatrates.filter(item => item.id === linesTmp[index].categoryVatRule[idStore][indexRule].vat_rate_id)[0]
                                    }

                                    break
                                default:
                                    alert("Impossible de mettre à jour la donnée")
                                    break
                            }
                        }

                        setValues(prev => ({
                            ...prev,
                            categoriesLines: linesTmp
                        }))

                        break
                    default: break
                }

                break
            case "barcode":
                let barcodesTmp = values.barcode.slice()

                switch (attribute) {
                    case "add":
                        barcodesTmp.push({
                            toAdd: true,
                            toUpdate: false,
                            toDelete: false,
                            barcode: val,
                            conditioning: 1,
                            created_at: new Date(),
                            updated_at: null,
                            deleted_at: null
                        })
                        break
                    case "remove":
                        let indexToRemove = barcodesTmp.findIndex(_ => _.barcode === val)

                        if (indexToRemove >= 0) {
                            if (barcodesTmp[indexToRemove].toAdd)
                                barcodesTmp.splice(indexToRemove, 1)
                            else {
                                barcodesTmp[indexToRemove].toDelete = true
                                barcodesTmp[indexToRemove].deleted_at = new Date()
                            }
                        }

                        break
                    default: break
                }

                setValues(prev => ({
                    ...prev,
                    barcode: barcodesTmp
                }))

                break
            default:
                let value = FormBuilder.buildVal(returnType, val);
                let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int")
                index = value;

                if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
                    value = filtered[0].list[index].id !== null ? parseInt(filtered[0].list[index].id) : 0

                    if (filtered[0].list[index].type !== undefined && values[attribute.replace("_id", "_type")] !== undefined) {
                        setValues(prev => ({
                            ...prev,
                            [attribute]: value,
                            [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                        }))
                    }
                    else {
                        setValues(prev => ({
                            ...prev,
                            [attribute]: value
                        }))
                    }
                }
                else {
                    filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

                    if (filtered.length > 0) {
                        if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                            value = null
                    }

                    if (objectType === "display" && attribute === "model") {
                        let display = DisplayController._displays.filter(_ => _.model === value)

                        if (display.length > 0) {
                            setValues(prev => ({
                                ...prev,
                                [attribute]: value,
                                nbLines: display[0].nbLines,
                                nbChars: display[0].maxChars
                            }))
                        }
                    }
                    else if (objectType === "printer" && attribute === "model") {
                        let printer = PrinterController._printers.filter(_ => _.model === value)

                        if (printer.length > 0) {
                            setValues(prev => ({
                                ...prev,
                                [attribute]: value,
                                nbCharPerLine: printer[0].nbCharPerLine
                            }))
                        }
                    }
                    else if (objectType === "product" && attribute === "name") { // acrocher le label et le name par défaut
                        if (object.label === object.name) {
                            setValues(prev => ({
                                ...prev,
                                [attribute]: value,
                                label: value
                            }))
                        }
                        else {
                            setValues(prev => ({
                                ...prev,
                                [attribute]: value
                            }))
                        }
                    }
                    else {
                        setValues(prev => ({
                            ...prev,
                            [attribute]: value
                        }))
                    }
                }

                if (attribute === "category_id") {
                    if (filtered.length > 0 && filtered[0].list.length > 0 && filtered[0].list[index].object !== undefined) {
                        change(rows, "vat_rate_id", "int", filtered[0].list[index].object.vat.id, true)
                    }
                }

                break
        }

        setUpdated(true)
    }
    const addPrice = (idTarget, idStore) => {
        let linesTmp
        let idStoresToUpdate = getIdStoresToUpdate(idStore)
        let index = -1

        switch (objectType) {
            case "product":
                linesTmp = values.pricelist.slice()
                index = linesTmp.findIndex(object => {
                    return object.pricelist.id === idTarget
                })

                break
            case "pricelist":
                linesTmp = values.categoriesLines.slice()
                index = linesTmp.findIndex(object => {
                    return object.object.id === idTarget
                })

                break
            default: break
        }

        if (index < 0)  {
            alert("Impossible de mettre à jour la donnée")
            return
        }

        for (let i in idStoresToUpdate) {
            idStore = idStoresToUpdate[i]

            linesTmp[index].toUpdate[idStore] = true

            switch (objectType) {
                case "product":
                    linesTmp[index].productPrice[idStore] = new Price({
                        value: 0,
                        stringValue: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(0).replace(" €", ""),
                        price_list_id: idTarget,
                        priceable_id: object.id,
                        priceable_type: "Product",
                        variation_id: null,
                        product_id : object.id
                    })
                    break
                case "pricelist":
                    linesTmp[index].categoryPriceRule[idStore].push(new PriceVariationRule({
                        variationOperator: "-",
                        value: 0,
                        type: "PERCENT",
                        catalog_id: urlParams.idCatalog,
                        price_list_id: object.id,
                        priceable_id: idTarget,
                        priceable_type: "Category"
                    }))
                    break
                default: break
            }
        }

        switch (objectType) {
            case "product":
                setValues(prev => ({
                    ...prev,
                    pricelist: linesTmp
                }))
                break
            case "pricelist":
                setValues(prev => ({
                    ...prev,
                    categoriesLines: linesTmp
                }))
                break
            default: break
        }

        setUpdated(true)
    }
    const removePrice = (idTarget, idStore) => {
        let linesTmp
        let idStoresToUpdate = getIdStoresToUpdate(idStore)
        let index = -1

        switch (objectType) {
            case "product":
                linesTmp = values.pricelist.slice()
                index = linesTmp.findIndex(object => {
                    return object.pricelist.id === idTarget
                })

                break
            case "pricelist":
                linesTmp = values.categoriesLines.slice()
                index = linesTmp.findIndex(object => {
                    return object.object.id === idTarget
                })

                break
            default: break
        }

        if (index < 0)  {
            alert("Impossible de mettre à jour la donnée")
            return
        }

        for (let i in idStoresToUpdate) {
            idStore = idStoresToUpdate[i]

            linesTmp[index].toUpdate[idStore] = true

            switch (objectType) {
                case "product":
                    linesTmp[index].productPriceVariation[idStore] = null
                    linesTmp[index].productPrice[idStore] = null

                    if (linesTmp[index].pricelist.main === 1) {
                        for (let i = 0; i < linesTmp.length; i++) {
                            linesTmp[i].productPriceVariation[idStore] = null
                            linesTmp[i].productPrice[idStore] = null
                        }
                    }

                    break
                case "pricelist":
                    let indexPriceRule = linesTmp[index].categoryPriceRule[idStore].findIndex(_ => _.price_list_id === object.id)
                    linesTmp[index].categoryPriceRule[idStore].splice(indexPriceRule, 1)
                    break
                default: break
            }

        }

        switch (objectType) {
            case "product":
                setValues(prev => ({
                    ...prev,
                    pricelist: linesTmp
                }))
                break
            case "pricelist":
                setValues(prev => ({
                    ...prev,
                    categoriesLines: linesTmp
                }))
                break
            default: break
        }

        setUpdated(true)
    }
    const recovery = (idPricelist, idStore, type, mainPrice) => {
        const linesTmp = values.pricelist.slice()
        const index = linesTmp.findIndex(object => {
            return object.pricelist.id === idPricelist
        })
        let valueToSave
        let idStoresToUpdate = getIdStoresToUpdate(idStore)

        if (index < 0)  {
            alert("Impossible de mettre à jour la donnée")
            return
        }

        for (let i in idStoresToUpdate) {
            idStore = idStoresToUpdate[i]

            linesTmp[index].toUpdate[idStore] = true

            switch (type) {
                case "variation":
                    linesTmp[index].productPriceVariation[idStore].rule_id = linesTmp[index].categoryPriceRule[idStore].id
                    linesTmp[index].productPriceVariation[idStore].variationOperator = linesTmp[index].categoryPriceRule[idStore].variationOperator
                    linesTmp[index].productPriceVariation[idStore].value = linesTmp[index].categoryPriceRule[idStore].value
                    linesTmp[index].productPriceVariation[idStore].type = linesTmp[index].categoryPriceRule[idStore].type

                    // Calcul all unit prices
                    for (let key in linesTmp) {
                        if (linesTmp[key].productPrice[idStore] === null) continue;

                        valueToSave = 0

                        if (linesTmp[key].productPriceVariation[idStore] === null) {
                            valueToSave = mainPrice
                        }
                        else {
                            const basePrice = mainPrice
                            let variationValue = 0

                            if (typeof linesTmp[key].productPriceVariation.value === "string") {
                                variationValue = FormBuilder.buildVal("float", linesTmp[key].productPriceVariation.value.replace(',', '.'))
                            }
                            else {
                                variationValue = linesTmp[key].productPriceVariation.value
                            }

                            switch (linesTmp[key].productPriceVariation.type) {
                                case "DEVISE":
                                    switch (linesTmp[key].productPriceVariation.variationOperator) {
                                        case "=":
                                            valueToSave = variationValue
                                            break
                                        case "-":
                                            valueToSave = basePrice - variationValue
                                            break
                                        case "+":
                                            valueToSave = basePrice + variationValue
                                            break
                                        default:
                                            valueToSave = basePrice
                                            break
                                    }

                                    break;
                                case "PERCENT":
                                    switch (linesTmp[key].productPriceVariation.variationOperator) {
                                        case "-":
                                            valueToSave = basePrice * ((100 - variationValue) / 100)
                                            break
                                        case "+":
                                            valueToSave = basePrice * ((100 + variationValue) / 100)
                                            break
                                        default:
                                            valueToSave = basePrice
                                            break
                                    }

                                    break
                                default:
                                    valueToSave = basePrice
                                    break
                            }
                        }

                        linesTmp[key].productPrice[idStore].value = valueToSave
                        linesTmp[key].productPrice[idStore].stringValue = new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: 'EUR'
                        }).format(valueToSave).replace(" €", "")
                    }

                    break;
                case "vatRate":
                    linesTmp[index].productVat[idStore].vat_rule_id = linesTmp[index].categoryVatRule[idStore].id
                    linesTmp[index].productVat[idStore].vat_rate_id = linesTmp[index].categoryVatRule[idStore].vat_rate_id
                    linesTmp[index].productVatRate[idStore] = Object.assign({}, linesTmp[index].categoryVatRate[idStore])
                    break
                default:
                    break
            }
        }

        setValues(prev => ({
            ...prev,
            pricelist: linesTmp
        }))
        setUpdated(true)
    }
    const getIdStoresToUpdate = idStore => {
        const catalogs = JSON.parse(localStorage.getItem("catalogs"))
        let idStoresToUpdate = []

        if (idStore === 0) {
            if (env.type === "company") {
                let catalog = catalogs[catalogs.findIndex(_ => _.id === object.catalog_id)]

                for (let i in catalog.stores)
                    idStoresToUpdate.push(env.stores[env.stores.findIndex(_ => _.shortName === catalog.stores[i].shortName)].id)
            }
            else
                idStoresToUpdate.push(env.id)
        }
        else
            idStoresToUpdate.push(idStore)

        return idStoresToUpdate
    }
    const checkUpdates = () => {
        let datas = returnUpdates(buildController())

        switch (objectType) {
            case "product":
                if (locationParams.pathname.includes("pricelists")) {
                    let keys
                    let toReturn = true

                    for (let i = 0; i < values.pricelist.length; i++) {
                        keys = Object.keys(values.pricelist[i].toUpdate)

                        for (let index in keys) {
                            if (values.pricelist[i].toUpdate[keys[index]]) {
                                toReturn = false
                            }
                        }
                    }

                    if (toReturn) return null
                }
                else if (locationParams.pathname.includes("barcodes")) {
                    let toReturn = true

                    for (let i = 0; i < values.barcode.length; i++)
                        if (values.barcode[i].toAdd || values.barcode[i].toUpdate || values.barcode[i].toDelete)
                            toReturn = false

                    if (toReturn) return null
                }
                else {
                    if(Object.keys(datas).length === 0)
                        return null
                }

                break
            case "pricelist":
                if (locationParams.pathname.includes("categories")) {
                    let keys
                    let toReturn = true

                    for (let i = 0; i < values.categoriesLines.length; i++) {
                        keys = Object.keys(values.categoriesLines[i].toUpdate)

                        for (let index in keys) {
                            if (values.categoriesLines[i].toUpdate[keys[index]]) {
                                toReturn = false
                            }
                        }
                    }

                    if (toReturn) return null
                }
                else if (locationParams.pathname.includes("products")) {
                    let keys
                    let toReturn = true

                    for (let i = 0; i < values.productsLines.length; i++) {
                        keys = Object.keys(values.productsLines[i].toUpdate)

                        for (let index in keys) {
                            if (values.productsLines[i].toUpdate[keys[index]]) {
                                toReturn = false
                            }
                        }
                    }

                    if (toReturn) return null
                }
                else {
                    if(Object.keys(datas).length === 0)
                        return null
                }

                break
            case "seller":
                if (locationParams.pathname.includes("barcodes")) {
                    let toReturn = true

                    for (let i = 0; i < values.barcode.length; i++)
                        if (values.barcode[i].toAdd || values.barcode[i].toUpdate || values.barcode[i].toDelete)
                            toReturn = false

                    if (toReturn) return null
                }
                else {
                    if(Object.keys(datas).length === 0)
                        return null
                }

                break
            case "paymentmethod":
                if(Object.keys(datas).length === 0)
                    return null

                if (datas.peripheralTarget !== undefined && datas.peripheralTarget !== null) {
                    datas.peripheralTarget = datas.peripheralTarget.toLowerCase();
                }

                break;
            default:
                if(Object.keys(datas).length === 0)
                    return null

                break
        }

        return datas
    }
    const returnUpdates = controller => {
        return controller.returnUpdatesFromCompare(object, values)
    }
    const callCloseSheet = () => {
        if (updated) {
            if ($(".savingBar").length > 0) {
                $(".savingBar").addClass("wizz")

                setTimeout(() => {
                    $(".savingBar").removeClass("wizz")
                },1200)
            }
        }
        else {
            if (closeDisplay !== undefined)
                closeDisplay()
            else {
                if (previousLink !== undefined) {
                    if (objectType === "store")
                        history.push("/access")
                    else
                        history.push("/" + previousLink + ((savePage !== undefined && savePage !== 1) ? "/page/" + savePage : ""))
                }
                else {
                    previousAction()
                }
            }
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice();

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom";
                else errorsTmp["name"] = "Ce nom n'est pas valide";
                break;
            case "reference":
                if(empty) errorsTmp["reference"] = "Vous devez saisir une référence";
                else errorsTmp["reference"] = "Cette référence n'est pas valide";
                break;
            default: break;
        }

        setErrors(errorsTmp);
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["reference"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false);
        }
    }
    const explodeStoresForCatalog = stores => {
        let storesToAdd = []
        let storesToRemove = []
        let store

        for (let i in stores) {
            store = getStoreByName(stores[i])

            if (!object.stores.includes(store.shortName)) {
                storesToAdd.push(store)
            }
        }

        for (let i in object.stores) {
            store = getStoreByName(object.stores[i])

            if (!stores.includes(store.shortName))
                storesToRemove.push(store)
        }

        return {
            add: storesToAdd,
            remove: storesToRemove
        }
    }
    const save = () => {
        setGlobalError(null)

        let datas = checkUpdates()
        if (datas === null) return

        setSaving(true)

        const controller = buildController()
        controller._callback = handleReturnSave

        switch (objectType) {
            case "catalog":
                let keys= Object.keys(datas)

                if (Object.keys(datas).length > 0)
                    controller.put(object, datas)

                break
            case "category":
            case "subcategory":
                if (datas.vat_rate_id !== undefined) {
                    const vatRuleController = new VatRuleController();
                    let mainVatRule = object.vatRules.find(_ => _.pricelist.main === 1);

                    if (mainVatRule !== null && mainVatRule !== undefined) {
                        vatRuleController.put(urlParams.idCatalog, object.id, mainVatRule, { vat_rate_id: datas.vat_rate_id })
                    }
                }

                controller.put(urlParams.idCatalog, object, datas)

                break
            case "pricelist":
                if (locationParams.pathname.includes("categories"))
                    savePricelistCategories()
                else
                    controller.put(urlParams.idCatalog, object, datas)

                break
            case "product":
                if (locationParams.pathname.includes("pricelists"))
                    saveProductPricelists()
                else if (locationParams.pathname.includes("barcodes"))
                    saveBarcodes()
                else {
                    if(datas.category_id !== undefined)
                        datas.category_type = object.category_type
                    else if (datas.category_type !== undefined)
                        datas.category_id = object.category_id

                    controller.put(urlParams.idCatalog, object, datas)
                }

                break
            case "sellergroup":
            case "paymentmethod":
            case "store":
                controller.put(object, datas);
                break;
            case "seller":
                if (locationParams.pathname.includes("barcodes"))
                    saveSellerBarcodes();
                else {
                    controller.put(object, datas);

                    if (datas.screen_id !== undefined || datas.licenses !== undefined)
                        saveSellerSettings(datas);
                }

                break;
            case "setting":
                // control duplicate prefixes
                if (datas.barcodePrefixes !== undefined && Object.keys(datas.barcodePrefixes).length > 0) {
                    for (let key in datas.barcodePrefixes) {
                        for (let keyTest in datas.barcodePrefixes) {
                            if (key === keyTest) continue

                            if (datas.barcodePrefixes[key] === datas.barcodePrefixes[keyTest]) {
                                setGlobalError("Certains préfixes de codes barres sont identiques, vous devez corriger les doublons")
                                setSaving(false)
                                return
                            }
                        }
                    }
                }

                if (datas.receiptHeader !== undefined)
                    datas.receiptHeader = JSON.stringify(datas.receiptHeader)

                if (datas.receiptFooter !== undefined)
                    datas.receiptFooter = JSON.stringify(datas.receiptFooter)

                if (datas.barcodePrefixes !== undefined)
                    datas.barcodePrefixes = JSON.stringify(datas.barcodePrefixes)

                if (datas.sellerInterupt !== undefined) { // Inverse la valeur pour la compréhension du formulaire
                    datas.sellerInterupt = datas.sellerInterupt === 1 ? 0 : 1;
                }

                controller.put(object, datas)
                break
            case "printer":
            case "display":
            case "barcodereader":
            case "paymentterminal":
                datas = controller.completeDatasToUpdate(object, datas)
                controller.put(object, datas)
                break
            case "me":
                if (datas.fullname !== undefined)
                    delete datas.fullname

                controller.put(object, datas)
                break
            case "account":
                controller.put("store", idStore, object, datas)
                break
            default: break
        }
    }
    const saveProductPricelists = () => {
        const vatController = new VatController()
        const priceVariationController = new PriceVariationController()
        const priceController = new PriceController()
        let datas
        let indexVat
        let indexPriceVariation
        let indexPrice
        let objectVat
        let objectPrice
        let objectPriceVariation
        let datasToSend
        let alreadyDelete = false
        let idStores
        let idStore

        for (let i = 0; i < values.pricelist.length; i++) {
            datas = values.pricelist[i]
            idStores = Object.keys(datas.toUpdate)

            for (let index in idStores) {
                idStore = idStores[index]

                if (!datas.toUpdate[idStore])
                    continue

                if (env.type === "company") {
                    // eslint-disable-next-line no-loop-func
                    indexVat = object.vats.findIndex(_ => {
                        return (_.price_list_id === datas.pricelist.id && _.store_id === parseInt(idStore))
                    })
                    // eslint-disable-next-line no-loop-func
                    indexPriceVariation = object.price_variations.findIndex(_ => {
                        return (_.price_list_id === datas.pricelist.id && _.store_id === parseInt(idStore))
                    })
                    // eslint-disable-next-line no-loop-func
                    indexPrice = object.prices.findIndex(_ => {
                        return (_.price_list_id === datas.pricelist.id && _.store_id === parseInt(idStore))
                    })
                }
                else {
                    // eslint-disable-next-line no-loop-func
                    indexVat = object.vats.findIndex(_ => {
                        return _.price_list_id === datas.pricelist.id
                    })
                    // eslint-disable-next-line no-loop-func
                    indexPriceVariation = object.price_variations.findIndex(_ => {
                        return _.price_list_id === datas.pricelist.id
                    })
                    // eslint-disable-next-line no-loop-func
                    indexPrice = object.prices.findIndex(_ => {
                        return _.price_list_id === datas.pricelist.id
                    })
                }

                vatController._callback = handleReturnSave
                priceVariationController._callback = handleReturnSave
                priceController._callback = handleReturnSave

                setSaving(true)

                if (datas.productVat[idStore] !== null) {
                    objectVat = indexVat < 0 ? new Vat() : object.vats[indexVat]

                    if (datas.productVat[idStore].vat_rule_id !== null) {
                        datasToSend = vatController.returnUpdatesFromCompare(objectVat, {
                            price_list_id: datas.productVat[idStore].price_list_id,
                            vat_rule_id: datas.productVat[idStore].vat_rule_id
                        })

                        if (Object.keys(datasToSend).length > 0) {
                            datasToSend.price_list_id = datas.productVat[idStore].price_list_id
                            datasToSend.vat_rule_id = datas.productVat[idStore].vat_rule_id
                        }
                    }
                    else {
                        datasToSend = vatController.returnUpdatesFromCompare(objectVat, {
                            price_list_id: datas.productVat[idStore].price_list_id,
                            //vat_rule_id: datas.productVat[idStore].vat_rule_id,
                            vat_rate_id: datas.productVat[idStore].vat_rate_id
                        })

                        if (Object.keys(datasToSend).length > 0) {
                            datasToSend.price_list_id = datas.productVat[idStore].price_list_id
                            //datasToSend.vat_rule_id = datas.productVat[idStore].vat_rule_id
                            datasToSend.vat_rate_id = datas.productVat[idStore].vat_rate_id
                        }
                    }

                    if (Object.keys(datasToSend).length > 0) {
                        if (objectVat.id === 0) {
                            if (env.type === "company")
                                datasToSend.store_id = parseInt(idStore)

                            vatController.post(urlParams.idCatalog, object.id, datasToSend)
                        }
                        else {
                            vatController.put(urlParams.idCatalog, object.id, objectVat, datasToSend)
                        }
                    }
                }
                else {
                    if (indexVat >= 0) {
                        vatController.delete(urlParams.idCatalog, object.id, object.vats[indexVat])
                    }
                }

                if (datas.pricelist.main === 1) {
                    objectPrice = indexPrice < 0 ? new Price() : object.prices[indexPrice]

                    if (datas.productPrice[idStore] !== null) {
                        datasToSend = vatController.returnUpdatesFromCompare(objectPrice, {
                            price_list_id: datas.productPrice[idStore].price_list_id,
                            value: datas.productPrice[idStore].value
                        })

                        if (Object.keys(datasToSend).length > 0) {
                            datasToSend.price_list_id = datas.productPrice[idStore].price_list_id
                            datasToSend.value = datas.productPrice[idStore].value
                        }

                        if (Object.keys(datasToSend).length > 0) {
                            if (datas.productPrice[idStore].id === 0) {
                                if (env.type === "company")
                                    datasToSend.store_id = parseInt(idStore)

                                priceController.post(urlParams.idCatalog, object.id, datasToSend)
                            }
                            else {
                                priceController.put(urlParams.idCatalog, object.id, objectPrice, datasToSend)
                            }
                        }
                    }
                    else {
                        if (indexPrice >= 0)
                            priceController.delete(urlParams.idCatalog, object.id, objectPrice)
                    }
                }
                else {
                    if (datas.productPriceVariation[idStore] !== null) {
                        objectPriceVariation = indexPriceVariation < 0 ? new PriceVariation() : object.price_variations[indexPriceVariation]
                        objectPrice = indexPrice < 0 ? new Price() : object.prices[indexPrice]

                        if (datas.productPriceVariation[idStore].rule_id !== null) {
                            datasToSend = priceVariationController.returnUpdatesFromCompare(objectPriceVariation, {
                                price_list_id: datas.productPriceVariation[idStore].price_list_id,
                                rule_id: datas.productPriceVariation[idStore].rule_id
                            })

                            if (Object.keys(datasToSend).length > 0) {
                                datasToSend.price_list_id = datas.productPriceVariation[idStore].price_list_id
                                datasToSend.rule_id = datas.productPriceVariation[idStore].rule_id
                            }
                        }
                        else {
                            datasToSend = priceVariationController.returnUpdatesFromCompare(objectPriceVariation, {
                                price_list_id: datas.productPriceVariation[idStore].price_list_id,
                                variationOperator: datas.productPriceVariation[idStore].variationOperator,
                                value: datas.productPriceVariation[idStore].value,
                                type: datas.productPriceVariation[idStore].type,
                                rule_id: datas.productPriceVariation[idStore].rule_id
                            })

                            if (Object.keys(datasToSend).length > 0) {
                                datasToSend.price_list_id = datas.productPriceVariation[idStore].price_list_id
                                datasToSend.variationOperator = datas.productPriceVariation[idStore].variationOperator
                                datasToSend.value = datas.productPriceVariation[idStore].value
                                datasToSend.type = datas.productPriceVariation[idStore].type
                                datasToSend.rule_id = datas.productPriceVariation[idStore].rule_id
                            }
                        }

                        if (Object.keys(datasToSend).length > 0) {
                            if (datas.productPriceVariation[idStore].id === 0) {
                                if (env.type === "company")
                                    datasToSend.store_id = parseInt(idStore)

                                priceVariationController.post(urlParams.idCatalog, object.id, datasToSend)
                            }
                            else {
                                priceVariationController.put(urlParams.idCatalog, object.id, objectPrice, datasToSend)
                            }
                        }
                    }
                    else {
                        if (!alreadyDelete && indexPriceVariation >= 0) {
                            priceVariationController.delete(urlParams.idCatalog, object.id, object.price_variations[indexPriceVariation])
                        }
                    }
                }
            }
        }
    }
    const savePricelistCategories = () => {
        const vatRuleController = new VatRuleController()
        const priceVariationRuleController = new PriceVariationRuleController()
        let datas
        let category
        let indexVatRule
        let indexPriceVariationRule
        let objectVatRule
        let objectPriceVariationRule
        let datasToSend
        let alreadyDelete = false
        let idStores
        let idStore
        let vatRule
        let priceRule

        for (let i = 0; i < values.categoriesLines.length; i++) {
            datas = values.categoriesLines[i]
            category = categories.find(_ => _.id === datas.object.id)
            idStores = Object.keys(datas.toUpdate)

            for (let index in idStores) {
                idStore = idStores[index]

                if (!datas.toUpdate[idStore])
                    continue

                if (env.type === "company") {
                    // eslint-disable-next-line no-loop-func
                    indexVatRule = category.vatRules.findIndex(_ => {
                        return (_.price_list_id === object.id && _.store_id === parseInt(idStore))
                    })
                    // eslint-disable-next-line no-loop-func
                    indexPriceVariationRule = category.priceRules.findIndex(_ => {
                        return (_.price_list_id === object.id && _.store_id === parseInt(idStore))
                    })
                }
                else {
                    // eslint-disable-next-line no-loop-func
                    indexVatRule = category.vatRules.findIndex(_ => {
                        return _.price_list_id === object.id
                    })
                    // eslint-disable-next-line no-loop-func
                    indexPriceVariationRule = category.priceRules.findIndex(_ => {
                        return _.price_list_id === object.id
                    })
                }

                vatRuleController._callback = handleReturnSave
                priceVariationRuleController._callback = handleReturnSave

                setSaving(true)

                if (datas.categoryVatRule[idStore].find(_ => _.price_list_id === object.id) !== undefined) {
                    vatRule = datas.categoryVatRule[idStore].find(_ => _.price_list_id === object.id)
                    objectVatRule = indexVatRule < 0 ? new VatRule() : category.vatRules[indexVatRule]

                    if (vatRule.vat_rule_id !== null) {
                        datasToSend = vatRuleController.returnUpdatesFromCompare(objectVatRule, {
                            price_list_id: vatRule.price_list_id,
                            vat_rule_id: vatRule.vat_rule_id
                        })

                        if (Object.keys(datasToSend).length > 0) {
                            datasToSend.price_list_id = vatRule.price_list_id
                            datasToSend.vat_rule_id = vatRule.vat_rule_id
                        }
                    }
                    else {
                        datasToSend = vatRuleController.returnUpdatesFromCompare(objectVatRule, {
                            price_list_id: vatRule.price_list_id,
                            //vat_rule_id: vatRule.vat_rule_id,
                            vat_rate_id: vatRule.vat_rate_id
                        })

                        if (Object.keys(datasToSend).length > 0) {
                            datasToSend.price_list_id = vatRule.price_list_id
                            //datasToSend.vat_rule_id = vatRule.vat_rule_id
                            datasToSend.vat_rate_id = vatRule.vat_rate_id
                        }
                    }

                    if (Object.keys(datasToSend).length > 0) {
                        if (vatRule.id === 0) {
                            if (env.type === "company")
                                datasToSend.store_id = parseInt(idStore)

                            vatRuleController.post(urlParams.idCatalog, category.id, datasToSend)
                        }
                        else
                            vatRuleController.put(urlParams.idCatalog, category.id, objectVatRule, datasToSend)
                    }
                }
                else {
                    if (indexVatRule >= 0)
                        vatRuleController.delete(urlParams.idCatalog, category.id, category.vatRules[indexVatRule])
                }

                if (datas.categoryPriceRule[idStore].find(_ => _.price_list_id === object.id) !== undefined) {
                    priceRule = datas.categoryPriceRule[idStore].find(_ => _.price_list_id === object.id)
                    objectPriceVariationRule = indexPriceVariationRule < 0 ? new PriceVariationRule() : category.priceRules[indexPriceVariationRule]

                    if (priceRule.rule_id !== null) {
                        datasToSend = priceVariationRuleController.returnUpdatesFromCompare(objectPriceVariationRule, {
                            price_list_id: priceRule.price_list_id,
                            rule_id: priceRule.rule_id
                        })

                        if (Object.keys(datasToSend).length > 0) {
                            datasToSend.price_list_id = priceRule.price_list_id
                            datasToSend.rule_id = priceRule.rule_id
                        }
                    }
                    else {
                        datasToSend = priceVariationRuleController.returnUpdatesFromCompare(objectPriceVariationRule, {
                            price_list_id: priceRule.price_list_id,
                            variationOperator: priceRule.variationOperator,
                            value: priceRule.value,
                            type: priceRule.type
                        })

                        if (Object.keys(datasToSend).length > 0) {
                            datasToSend.price_list_id = priceRule.price_list_id
                            datasToSend.variationOperator = priceRule.variationOperator
                            datasToSend.value = priceRule.value
                            datasToSend.type = priceRule.type
                        }
                    }

                    if (Object.keys(datasToSend).length > 0) {
                        if (priceRule.id === 0) {
                            if (env.type === "company")
                                datasToSend.store_id = parseInt(idStore)

                            priceVariationRuleController.post(urlParams.idCatalog, category.id, datasToSend)
                        }
                        else
                            priceVariationRuleController.put(urlParams.idCatalog, category.id, objectPriceVariationRule, datasToSend)
                    }
                }
                else {
                    if (!alreadyDelete && indexPriceVariationRule >= 0)
                        priceVariationRuleController.delete(urlParams.idCatalog, category.id, category.priceRules[indexPriceVariationRule])
                }
            }
        }
    }
    const saveBarcodes = () => {
        const controller = new ProductController()
        controller._callback = handleReturnSave;

        setSaving(true);

        for (let i = 0; i < values.barcode.length; i++) {
            if (values.barcode[i].toAdd) {
                controller.postBarcode(urlParams.idCatalog, object.id, {
                    "barcode": values.barcode[i].barcode,
                    "condinioning": values.barcode[i].condinioning
                })
            }
            else if (values.barcode[i].ToUpdate) {
                controller.putBarcode(urlParams.idCatalog, object.id, values.barcode[i].id, {
                    "barcode": values.barcode[i].barcode,
                    "condinioning": values.barcode[i].condinioning
                })
            }
            else if (values.barcode[i].toDelete) {
                controller.deleteBarcode(urlParams.idCatalog, object.id, values.barcode[i].id)
            }
        }
    }
    const saveSellerBarcodes = () => {
        const controller = new SellerController()
        controller._callback = handleReturnSave;

        setSaving(true);

        for (let i = 0; i < values.barcode.length; i++) {
            if (values.barcode[i].toAdd) {
                controller.postBarcode(object.id, {
                    "barcode": values.barcode[i].barcode,
                    "condinioning": values.barcode[i].condinioning
                })
            }
            else if (values.barcode[i].ToUpdate) {
                controller.putBarcode(object.id, values.barcode[i].id, {
                    "barcode": values.barcode[i].barcode,
                    "condinioning": values.barcode[i].condinioning
                })
            }
            else if (values.barcode[i].toDelete) {
                controller.deleteBarcode(object.id, values.barcode[i].id)
            }
        }
    }
    const saveSellerSettings = sellerDatas => {
        const sellerSettingController = new SellerSettingController();
        let datas = {};

        if (sellerDatas.screen_id !== undefined) {
            datas.screen_id = sellerDatas.screen_id === 0 ? null : sellerDatas.screen_id;
        }

        if (sellerDatas.licenses !== undefined) {
            datas.licenses = sellerDatas.licenses;
        }

        sellerSettingController.put(object, datas);
    }
    const handleReturnSave = (response, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 422)
                    check422Errors(error.response.data)
                else
                    setGlobalError("Une erreur s'est produite lors de l'enregistrement")
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement")
        }
        else
            show(true)

        setSaving(false)
        setUpdated(false)
    }
    const reinit = () => {
        setGlobalError(null)
        initValues()
        setUpdated(false)
    }
    const getCategoryBySubCategory = () => {
        for (let i in categories) {
            if (categories[i].subCategories.find(_ => _.id === object.id) !== undefined)
                return categories[i];
        }

        return null;
    }
    const checkItemStyle = (type, category) => {
        let style = "";

        if (objectType === type && object.id === category.id) {
            style = " actu";
        }

        if (categoryToMoved !== null && categoryToMoved.type === type && categoryToMoved.id === category.id) {
            style += " selected";
        }

        return style;
    }
    const selectCategoryToMoved = datas => {
        if (objectType === datas.type && object.id === datas.id)
            return;

        datas.action = "remove";
        setCategoryToMoved(datas);
    }
    const buildListCategories = () => {
        if (categories === null) {
            return <LoaderCircle display="loader logWait smaller marginTop" strokeWidth="8" stroke="#9C9C9C" />;
        }

        switch (objectType) {
            case "category":
                return <div className={"listItemInOverbox"}>
                    {
                        categories.map((category, indexItem) => (
                            <div key={indexItem} className={"containerItem"}>
                                <p className={"item" + checkItemStyle("category", category)} onClick={ () => { selectCategoryToMoved({ type: "category", id: category.id, name: category.name }) } }>{category.name}</p>
                            </div>
                        ))
                    }
                </div>;
            case "subcategory":
                let category = getCategoryBySubCategory();
                if (category === null) return <></>;

                return <div className={"listItemInOverbox"}>
                    <div className={"containerItem"}>
                        <p className={"item" + checkItemStyle("category", category)} onClick={ () => { selectCategoryToMoved({ type: "category", id: category.id, name: category.name }) } }>{category.name}</p>
                        <div className={"containerSubItem"}>
                            {
                                category.subCategories.map((subCat, index) => (
                                    <p key={index} className={"subItem" + checkItemStyle("subcategory", subCat)} onClick={ () => { selectCategoryToMoved({ type: "subcategory", id: subCat.id, name: subCat.name }) } }>{ subCat.name }</p>
                                ))
                            }
                        </div>
                    </div>
                </div>;
            default:
                return <></>;
        }
    }
    const confirmMove = () => {
        if (categoryToMoved === null) {
            setErrorText("Vous devez sélectionner une catégorie");
            return;
        }

        move(categoryToMoved);
        setMoveWindow(false);
    }
    const abortMove = () => {
        setCategoryToMoved(null);
        setMoveWindow(false);
    }
    const move = datas => {
        let controller, datasToSend, catalogId, categoryId;

        if (datas.action === "move") {
            switch (objectType) {
                case "product":
                    switch (object.category_type) {
                        case "categories":
                            controller = new CategoryController();
                            break;
                        case "subcategories":
                            controller = new SubCategoryController();
                            break;
                    }

                    datasToSend = {
                        products: { ids: [object.id] },
                        target_type: datas.type,
                        target_id: datas.id
                    };

                    break;
                case "subcategory":
                    controller = new CategoryController();

                    datasToSend = {
                        subcategories: { ids: [object.id] },
                        target_type: datas.type,
                        target_id: datas.id
                    };

                    break;
                default:
                    setErrorText("Impossible de déplacer l'élément");
                    return;
            }

            catalogId = object.catalog_id;
            categoryId = object.category_id;
        }
        else if (datas.action === "remove") {
            switch (objectType) {
                case "category":
                    controller = new CategoryController();
                    break;
                case "subcategory":
                    controller = new SubCategoryController();
                    break;
                default:
                    setErrorText("Impossible de déplacer le contenu");
                    return;
            }

            datasToSend = {
                all: true,
                target_type: datas.type === "category" ? "categories" : "subcategories",
                target_id: datas.id
            };

            catalogId = object.catalog_id;
            categoryId = object.id;

            setDeleting(true);
        }

        setMoving(true);

        controller._callback = handleReturnMove;
        controller.transfer(
            catalogId,
            categoryId,
            datasToSend,
            {
                action: datas.action
            }
        );
    }
    const handleReturnMove = (response, error, status, context) => {
        setMoving(false);

        switch (status) {
            case 204:
                switch (context.action) {
                    case "move":
                        setValidText("Le produit à été déplacer");
                        show(true);
                        break;
                    case "remove":
                        const controller = buildController()
                        controller._callback = handleReturnRemove;
                        controller.delete(object);

                        break;
                }

                break;
            default:
                if (context !== undefined) {
                    switch (context.action) {
                        case "move":
                            setErrorText("Impossible de déplacer " + (objectType === "product" ? "le produit" : "la sous-catégorie"));
                            break;
                        case "remove":
                            setErrorText("Une erreur s'est produite lors du déplacement du contenu");
                            break;
                        default:
                            setErrorText("Une erreur s'est produite lors du déplacement");
                            break;
                    }
                }
                else
                    setErrorText("Une erreur s'est produite lors du déplacement");

                break;
        }
    }

    useEffect(() => {
        if (existingObject === undefined)
            show()
    }, []);
    useEffect(() => {
        if (object === null) return

        switch (objectType) {
            case "product":
                if (loadingCategory && loadingPricelists && loadingVatRates && loadingStoreSettings) {
                    getCategory()
                    getPricelists()
                    getVatRates()
                    getStoreSettings(object.catalog.owner_id)
                }
                else
                    initValues()

                break
            case "pricelist":
                if (loadingCategories && loadingProducts && loadingVatRates && loadingPricelists) {
                    getCategories()
                    //getProducts()
                    getVatRates()
                    getPricelists()
                }
                else
                    initValues()

                break
            case "sellergroup":
                if (loadingActions)
                    getActions()
                else
                    initValues()
                break
            case "seller":
                if (loadingSellerGroups && loadingStoreSettings) {
                    getSellerGroups()
                    getStoreSettings(object.store_id)
                }
                else
                    initValues()
                break
            case "paymentmethod":
                if (loadingPredefinedPaymentMethods)
                    getPredefinedPaymentMethods()
                else
                    initValues()
                break
            default:
                initValues()
                break
        }
    }, [object]);
    useEffect(() => {
        switch (objectType) {
            case "product":
                if (!loadingCategory && !loadingPricelists && !loadingVatRates && !loadingStoreSettings)
                    initValues()

                break
            case "pricelist":
                if (!loadingCategories && !loadingProducts && !loadingVatRates && !loadingPricelists)
                    initValues()

                break
            case "sellergroup":
                if (!loadingActions)
                    initValues()

                break
            case "seller":
                if (!loadingSellerGroups && !loadingStoreSettings)
                    initValues()

                break
            case "paymentmethod":
                if (!loadingPredefinedPaymentMethods)
                    initValues()

                break
            default: break
        }
    }, [loadingCategory, loadingCategories, loadingProducts, loadingPricelists, loadingVatRates, loadingStoreSettings, loadingActions, loadingSellerGroups, loadingPredefinedPaymentMethods])
    useEffect(() => {
        if (Object.keys(values).length > 0) {
            setLoading(false)
            setUpdated(checkUpdates() !== null)
        }
    }, [values]);
    useEffect(() => {
        initRemoveChoices();
    }, [removePopup]);
    useEffect(() => {
        if (moveWindow)
            getCategories();
        else
            setCategoryToMoved(null);
    }, [moveWindow]);
    useEffect(() => {
        if (!moving && categoryToMoved !== null)
            setCategoryToMoved(null);
    }, [moving]);

    return (
        <div className="overlayer">
            {
                closeSheet
                && <Redirect to={ "/" + previousLink } />
            }
            <div className="wrapOverbox">
                <div className="overbox sheet">
                    {
                        (
                            loading
                                ? <div className="wrapCenterLoader">
                                    <LoaderCircle
                                        display="loader restGETInForm"
                                        strokeWidth="8" />
                                </div>
                                : <div className="wrapForm tab">
                                    {
                                        buildHeaderSheet()
                                    }
                                    {
                                        globalError !== null
                                        && <p className="sheetError">{ globalError }</p>
                                    }
                                    {
                                        buildTabulate()
                                    }
                                    <div className={ "scrollview" + (globalError !== null ? " withError" : "") + (getTabulatesByType().length <= 1 ? " noTab" : "") }>
                                        {
                                            buildFormSheet()
                                        }
                                        {
                                            updated
                                            && <div className="savingBar">
                                                <p className="text">Vous avez des modifications à enregistrer</p>
                                                <p className="save" onClick={ save }>Enregistrer</p>
                                                <p className="reinit" onClick={ reinit }>Réinitialiser</p>
                                                <div className="clearing" />
                                            </div>
                                        }
                                    </div>
                                    <div className="wrapButtons">
                                        <p className="cancel" onClick={ callCloseSheet }>
                                            {
                                                saving
                                                    ? "Enregistrement en cours..."
                                                    : "Fermer"
                                            }
                                            <LoaderCircle display="loader submitForm " hide={ !saving ? "hide" : "" } strokeWidth="8" stroke="#00406F"/>
                                        </p>
                                        <div className="clearing" />
                                    </div>
                                </div>
                        )
                    }
                </div>
            </div>
            {
                removePopup &&
                <div className="overlayer hover">
                    <div className="wrapOverbox">
                        <div className="overbox hover choices extended">
                            <div className="form">
                                <p className="titleForm center">Supprimer définitivement { textRemoveButton } ?</p>
                                {
                                    buildDescriptionRemove()
                                }
                                {
                                    buildChoiceRemove()
                                }
                                <button className={"submit red " + (deleting ? "hide" : "") } onClick={ remove }>
                                    {
                                        deleting ? "Supression..." : "Supprimer"
                                    }
                                    <LoaderCircle display="loader submitForm " hide={!deleting ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                                </button>
                                <button className="cancel align" onClick={ callToRemove }>Annuler</button>
                                <div className="clearing" />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                choiceCatalogRemove &&
                <div className="overlayer hover">
                    <div className="wrapOverbox">
                        <div className="overbox choices extend">
                            <div className="form margin">
                                <p className="titleForm center">Que souhaitez-vous faire ?</p>
                                <div className="horizontalContButtons">
                                    <button className="buttonChoice two red icon" onClick={ () => { choiceRemove(1) } }>Ne plus gérer depuis le groupe</button>
                                    <button className="buttonChoice two red icon" onClick={ () => { choiceRemove(2) } }>Supprimer le catalogue</button>
                                </div>
                                <button className="cancel block margin" onClick={ closeMultiChoiceRemove }>Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                choiceCatalogRemoveInStore &&
                <div className="overlayer hover">
                    <div className="wrapOverbox">
                        <div className="overbox choices extend">
                            <div className="form margin">
                                <p className="titleForm center">Que souhaitez-vous faire ?</p>
                                <div className="horizontalContButtons">
                                    <button className="buttonChoice two red icon" onClick={ () => { choiceRemove(3) } }>Détacher et laisser le contenu du catalogue</button>
                                    <button className="buttonChoice two red icon" onClick={ () => { choiceRemove(4) } }>Supprimer le catalogue sur la boutique</button>
                                </div>
                                <button className="cancel block margin" onClick={ closeMultiChoiceRemove }>Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                removeSecurityBox !== null
                && <SecureBox
                    obj={ object }
                    title="Veuillez saisir le code de sécurité"
                    textBack="Annuler"
                    textConfirm="Confirmer"
                    textConfirming="Suppression du catalogue..."
                    loading={ deleting }
                    handleClose={ closeSecurityBox }
                    handleConfirm={ remove } />
            }
            {
                moveWindow
                && <div className="overlayer hover">
                    <div className="wrapOverbox">
                        <div className="overbox hover choices extend">
                            <div className="form">
                                <p className="titleForm center">Déplacer le contenu</p>
                                <p className={"warning"}>
                                    <strong>Attention</strong> : Déplacer le contenu dans une autre catégorie modifiera automatiquement les règles de TVA et de prix si celle-ci sont différentes.
                                </p>
                                {
                                    buildListCategories()
                                }
                                <button className={"submit red"} onClick={confirmMove}>Confirmer</button>
                                <button className="cancel align" onClick={abortMove}>Annuler</button>
                                <div className="clearing"/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ObjectSheet;
