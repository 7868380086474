import React, { useState } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Login from "./component/page/content/login/Login"
import Access from "./component/page/content/empire/Access"
import Companies from "./component/page/content/login/Companies"
import BackOffice from "./component/page/BackOffice"
import ForgotPwd from "./component/page/content/login/ForgotPwd"
import FormPwd from "./component/form/account/FormPwd"
import AuthController from "./stories/_auth/Auth/AuthController"
import Account from "./stories/_account/Accounts/Account"
import CompanyController from "./stories/_account/Companies/CompanyController"
import StoreController from "./stories/_account/Stores/StoreController"
import CatalogController from "./stories/_catalog/Catalogs/CatalogController"
import StoreSettingController from "./stories/_setting/StoreSettings/StoreSettingController"
import SessionContext from "./context/SessionContext"
import './css/Theme.css'

const Main = () => {
    const [ showBigBisoux, setShowBigBisoux ] = useState(false)

    const removeSession = () => {
        localStorage.clear()
        sessionStorage.clear()

        window.location.href = process.env.REACT_APP_URL
    }
    const logout = () => {
        const auth = new AuthController()
        auth._callback = handleLogout
        auth.logout()
    }
    const handleLogout = (response, error, status) => {
        removeSession()
    }
    const switchLogin = (type, id = null) => {
        if (type === "company") {
            localStorage.removeItem("store")
            localStorage.removeItem("storeSettings")
        }

        localStorage.setItem("switching", "true") // to escape 401 refresh on old queries

        const auth = new AuthController()
        auth._callback = handleSwitchLogin
        auth.switch(type, id)
    }
    const handleSwitchLogin = (response, error, status) => {
        localStorage.removeItem("switching") // remove escape process

        switch (status) {
            case 200:
                localStorage.setItem("token", response.data.token)
                localStorage.setItem("expires_in", addSeconds(Date.now(), response.data.expires_in).toString())
                localStorage.setItem("env", JSON.stringify(response.data.environment.env))

                getTarget(response.data.environment.env.type, response.data.environment.env.id)

                break
            default:
                handleLogout()
                break
        }
    }
    const getTarget = (type, id) => {
        let controller

        switch (type) {
            case "company":
                controller = new CompanyController()
                break
            case "store":
                controller = new StoreController()
                break
            default: return
        }

        controller._callback = handleGetTarget
        controller.show(id)
    }
    const handleGetTarget = (response, error, status) => {
        switch (status) {
            case 200:
                let env = JSON.parse(localStorage.getItem("env"))

                switch (env.type) {
                    case "company":
                        localStorage.setItem("company", JSON.stringify(response))
                        break
                    case "store":
                        localStorage.setItem("store", JSON.stringify(response))
                        break
                    default: break
                }

                getCatalogs()

                break
            default:
                handleLogout()
                break
        }
    }
    const getCatalogs = () => { 
        let controller = new CatalogController()
        controller._callback = handleGetCatalogs
        controller.index("", 0, 0, true, "name", "asc")
    }
    const handleGetCatalogs = (list, error, paginate, status) => {
        switch (status) {
            case 200:
                localStorage.setItem("catalogs", JSON.stringify(list))

                if (list.length === 1)
                    localStorage.setItem("catalog", JSON.stringify(list[0]))
                else
                    localStorage.setItem("catalog", JSON.stringify({}))

                let env = JSON.parse(localStorage.getItem("env"))

                if (env.type === "company")
                    window.location = "/"
                else
                    getStoreSettings()

                break
            default:
                handleLogout()
                break
        }
    }
    const getStoreSettings = () => {
        let controller = new StoreSettingController()
        controller._callback = handleGetStoreSettings
        controller.show(JSON.parse(localStorage.getItem("store")).id)
    }
    const handleGetStoreSettings = (object, error, status) => {
        switch (status) {
            case 200:
                localStorage.setItem("storeSettings", JSON.stringify(object))
                window.location = "/"
                break
            default:
                handleLogout()
                break
        }
    }
    const addSeconds = (date, seconds) => {
        return Math.floor(date / 1000) + seconds
    }
    const me = () => {
        const auth = new AuthController()
        auth._callback = handleMe
        auth.me()
    }
    const handleMe = (response, error, status) => {
        switch (status) {
            case 200:
                break
            default:
                removeSession()
                break
        }
    }
    const checkBigBisoux = () => {
        if (localStorage.getItem("user") === null) return
        let user = JSON.parse(localStorage.getItem("user"))

        if (user.bigBisoux === 1)
            setShowBigBisoux(true)
    }
    const closeBigBisoux = () => {
        setShowBigBisoux(false)
    }
    const redirectAfterLogin = (type, id) => {
        if (AuthController.hasRules(["admin", "skytech", "analyst"], type, id))
            return "/analyze"
        else if (AuthController.hasRules(["accountant"], type, id))
            return "/closing"
        else if (AuthController.hasRules(["products"], type, id))
            return "/products"
        else if (AuthController.hasRules(["manager"], type, id))
            return "/sellers"
        else if (AuthController.hasRules(["tech"], type, id))
            return "/printers"

        return ""
    }
    const redirectProcess = () => {
        if (localStorage.getItem("logged") !== null)
            removeSession()

        let pathname = window.location.pathname.replace("/", "")
        let emptyPath = [""]
        let loginPath = ["login"]

        // LOGIN PROCESS
        if (!loginPath.includes(pathname) && localStorage.getItem("user") === null) {
            window.location = "/login"
            return
        }

        // REFRESH OR UNLOG PROCESS
        const expiresIn = parseInt(localStorage.getItem("expires_in"))
        const rememberMe = localStorage.getItem("remember_me") === "true"

        if (expiresIn < (Date.now() / 1000) && rememberMe) {
            if (rememberMe)
                me()
            else
                removeSession()
        }

        // TARGET PROCESS IF NEEDED
        if (emptyPath.includes(pathname)) {
            const env = JSON.parse(localStorage.getItem("env"))

            switch (env.type) {
                case "empire":
                    window.location = "companies"
                    return
                case "company":
                    const company = JSON.parse(localStorage.getItem("company"))

                    if (env.rule.length === 0) {
                        if (env.stores.length > 1)
                            window.location = "access"
                        else
                            switchLogin("store", env.stores[0].id)
                    }
                    else {
                        if (Object.keys(company).length > 0)
                            window.location = redirectAfterLogin(env.type, env.id)
                        else
                            getTarget(env.type, env.id)
                    }

                    return
                case "store":
                    window.location = redirectAfterLogin(env.type, env.id)
                    return
                default: break
            }
        }
    }

    const valueSessionContext = {
        handleLogout: logout,
        handleSwitch: switchLogin,
        handleCheckBigBisoux: checkBigBisoux
    }

    return (
        <SessionContext.Provider value={valueSessionContext}>
            <Router>
                {
                    redirectProcess()
                }
                <Switch>
                    {/* LOGIN */}
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <Route exact path="/forgot">
                        <ForgotPwd />
                    </Route>

                    {/* SELECT COMPANY */}
                    <Route path="/companies">
                        <Companies />
                    </Route>

                    {/* ACCESS */}
                    <Route path="/access">
                        <Access />
                    </Route>

                    {/* STATISTIC */}
                    <Route path="/analyze">
                        <BackOffice page="analyze" />
                    </Route>

                    <Route exact path="/sales">
                        <BackOffice page="sales" />
                    </Route>
                    <Route exact path="/sales/page/:page">
                        <BackOffice page="sales" />
                    </Route>
                    <Route exact path="/sales/:id">
                        <BackOffice page="sales" />
                    </Route>

                    <Route path="/closing">
                        <BackOffice page="closing" />
                    </Route>

                    {/* CATALOG */}
                    <Route exact path="/catalogs">
                        <BackOffice page="catalogs" />
                    </Route>
                    <Route exact path="/catalogs/page/:page">
                        <BackOffice page="catalogs" />
                    </Route>
                    <Route exact path="/catalogs/new">
                        <BackOffice page="catalogs" />
                    </Route>
                    <Route exact path="/catalogs/:id/(information)">
                        <BackOffice page="catalogs" />
                    </Route>

                    <Route exact path="/pricelists">
                        <BackOffice page="pricelists" />
                    </Route>
                    <Route exact path="/pricelists/page/:page">
                        <BackOffice page="pricelists" />
                    </Route>
                    <Route exact path="/pricelists/new">
                        <BackOffice page="pricelists" />
                    </Route>
                    <Route exact path="/catalogs/:idCatalog/pricelists/:id/(information|categories)">
                        <BackOffice page="pricelists" />
                    </Route>

                    <Route exact path="/categories">
                        <BackOffice page="categories" />
                    </Route>
                    <Route exact path="/categories/page/:page">
                        <BackOffice page="categories" />
                    </Route>
                    <Route exact path="/categories/new">
                        <BackOffice page="categories" />
                    </Route>
                    <Route exact path="/catalogs/:idCatalog/categories/:id/(information|stats)">
                        <BackOffice page="categories" />
                    </Route>

                    <Route exact path="/subcategories">
                        <BackOffice page="subcategories" />
                    </Route>
                    <Route exact path="/subcategories/page/:page">
                        <BackOffice page="subcategories" />
                    </Route>
                    <Route exact path="/subcategories/new">
                        <BackOffice page="subcategories" />
                    </Route>
                    <Route exact path="/catalogs/:idCatalog/subcategories/:id/(information|stats)">
                        <BackOffice page="subcategories" />
                    </Route>

                    <Route exact path="/products">
                        <BackOffice page="products" />
                    </Route>
                    <Route exact path="/products/page/:page">
                        <BackOffice page="products" />
                    </Route>
                    <Route exact path="/products/new">
                        <BackOffice page="products" />
                    </Route>
                    <Route exact path="/catalogs/:idCatalog/products/:id/(information|pricelists|barcodes|stats)">
                        <BackOffice page="products" />
                    </Route>

                    {/* SELLER */}
                    <Route exact path="/sellergroups">
                        <BackOffice page="sellergroups" />
                    </Route>
                    <Route exact path="/sellergroups/page/:page">
                        <BackOffice page="sellergroups" />
                    </Route>
                    <Route exact path="/sellergroups/new">
                        <BackOffice page="sellergroups" />
                    </Route>
                    <Route exact path="/sellergroups/:id/(information|rules)">
                        <BackOffice page="sellergroups" />
                    </Route>

                    <Route exact path="/sellers">
                        <BackOffice page="sellers" />
                    </Route>
                    <Route exact path="/sellers/page/:page">
                        <BackOffice page="sellers" />
                    </Route>
                    <Route exact path="/sellers/new">
                        <BackOffice page="sellers" />
                    </Route>
                    <Route exact path="/sellers/:id/(information|barcodes)">
                        <BackOffice page="sellers" />
                    </Route>

                    {/* PERIPHERALS */}
                    <Route exact path="/printers">
                        <BackOffice page="printers" />
                    </Route>
                    <Route exact path="/printers/page/:page">
                        <BackOffice page="printers" />
                    </Route>
                    <Route exact path="/printers/new">
                        <BackOffice page="printers" />
                    </Route>
                    <Route exact path="/printers/:id/(information)">
                        <BackOffice page="printers" />
                    </Route>

                    <Route exact path="/displays">
                        <BackOffice page="displays" />
                    </Route>
                    <Route exact path="/displays/page/:page">
                        <BackOffice page="displays" />
                    </Route>
                    <Route exact path="/displays/new">
                        <BackOffice page="displays" />
                    </Route>
                    <Route exact path="/displays/:id/(information|settings)">
                        <BackOffice page="displays" />
                    </Route>

                    <Route exact path="/barcodereaders">
                        <BackOffice page="barcodereaders" />
                    </Route>
                    <Route exact path="/barcodereaders/page/:page">
                        <BackOffice page="barcodereaders" />
                    </Route>
                    <Route exact path="/barcodereaders/new">
                        <BackOffice page="barcodereaders" />
                    </Route>
                    <Route exact path="/barcodereaders/:id/(information)">
                        <BackOffice page="barcodereaders" />
                    </Route>

                    <Route exact path="/paymentterminals">
                        <BackOffice page="paymentterminals" />
                    </Route>
                    <Route exact path="/paymentterminals/page/:page">
                        <BackOffice page="paymentterminals" />
                    </Route>
                    <Route exact path="/paymentterminals/new">
                        <BackOffice page="paymentterminals" />
                    </Route>
                    <Route exact path="/paymentterminals/:id/(information)">
                        <BackOffice page="paymentterminals" />
                    </Route>

                    {/* SETTING */}
                    <Route exact path="/screens">
                        <BackOffice page="screens" />
                    </Route>
                    <Route exact path="/screens/page/:page">
                        <BackOffice page="screens" />
                    </Route>
                    <Route exact path="/screens/:idScreen">
                        <BackOffice page="screens" />
                    </Route>

                    <Route exact path="/paymentmethods">
                        <BackOffice page="paymentMethods" />
                    </Route>
                    <Route exact path="/paymentmethods/page/:page">
                        <BackOffice page="paymentMethods" />
                    </Route>
                    <Route exact path="/paymentmethods/new">
                        <BackOffice page="paymentMethods" />
                    </Route>
                    <Route exact path="/paymentmethods/:id/(information)">
                        <BackOffice page="paymentMethods" />
                    </Route>

                    {/* 404 */}
                    <Route exact path="">
                        <div />
                    </Route>
                    <Route exact path="/">
                        <div />
                    </Route>
                    <Route>
                        <BackOffice page="404" />
                    </Route>
                </Switch>
                {
                    showBigBisoux
                    && <FormPwd object={ new Account(JSON.parse(localStorage.getItem("user"))) } closeView={ closeBigBisoux } />
                }
            </Router>
        </SessionContext.Provider>
    )
}

export default Main
