import React, { useEffect, useState } from "react"
import InputSelect from "./InputSelect"
import InputText from "./InputText"
import CancelIcon from "../../../icons/CancelIcon"
import ReApplyIcon from "../../../icons/ReApplyIcon"
import CrossIcon from "../../../icons/CrossIcon"
import {useParams} from "react-router-dom";

const InputPricelist = props => {
    const { lines, pricelist, vatrates, pricelists, classname, shared, readonly, handleChange, handleAdd, handleRemove, handleRecovery } = props
    const [ detailsPricelist, setDetailsPricelist ] = useState([])
    const env = JSON.parse(localStorage.getItem("env"))
    const catalogs = JSON.parse(localStorage.getItem("catalogs"))
    const urlParams = useParams()

    const defineDetailsPricelist = () => {
        if (env.type === "store") return

        let prevVariationOperator
        let prevVariationValue
        let prevVariationType
        let prevVatRateId
        let index
        let first
        let quit
        let detailsPricelistTmp = detailsPricelist.slice()

        for (let i in lines) {
            prevVariationOperator = null
            prevVariationValue = null
            prevVariationType = null
            prevVatRateId = null
            quit = false
            first = true

            for (index in lines[i].categoryPriceRule) {
                if (first) {
                    if (lines[i].categoryPriceRule[index].find(_ => _.price_list_id === pricelist.id) !== undefined) {
                        prevVariationOperator = lines[i].categoryPriceRule[index].find(_ => _.price_list_id === pricelist.id).variationOperator
                        prevVariationValue = lines[i].categoryPriceRule[index].find(_ => _.price_list_id === pricelist.id).value
                        prevVariationType = lines[i].categoryPriceRule[index].find(_ => _.price_list_id === pricelist.id).type
                    }

                    first = false
                }
                else {
                    if (lines[i].categoryPriceRule[index].find(_ => _.price_list_id === pricelist.id) !== undefined) {
                        if (
                            prevVariationOperator !== lines[i].categoryPriceRule[index].find(_ => _.price_list_id === pricelist.id).variationOperator
                            || prevVariationValue !== lines[i].categoryPriceRule[index].find(_ => _.price_list_id === pricelist.id).value
                            || prevVariationType !== lines[i].categoryPriceRule[index].find(_ => _.price_list_id === pricelist.id).type
                        ) {
                            detailsPricelistTmp.push(lines[i].object.id)
                            quit = true
                            break
                        }
                    }
                    else {
                        if (prevVariationOperator !== null || prevVariationValue !== null || prevVariationType !== null) {
                            detailsPricelistTmp.push(lines[i].object.id)
                            quit = true
                            break
                        }
                    }
                }
            }

            if (quit) continue

            first = true

            for (index in lines[i].categoryVatRule) {
                if (first) {
                    if (lines[i].categoryVatRule[index].find(_ => _.price_list_id === pricelist.id) !== undefined)
                        prevVatRateId = lines[i].categoryVatRule[index].find(_ => _.price_list_id === pricelist.id).vat_rate_id

                    first = false
                }
                else {
                    if (lines[i].categoryVatRule[index].find(_ => _.price_list_id === pricelist.id) !== undefined) {
                        if (prevVatRateId !== lines[i].categoryVatRule[index].find(_ => _.price_list_id === pricelist.id).vat_rate_id) {
                            detailsPricelistTmp.push(lines[i].object.id)
                            break
                        }
                    }
                    else {
                        if (prevVatRateId !== null) {
                            detailsPricelistTmp.push(lines[i].object.id)
                            break
                        }
                    }
                }
            }
        }

        setDetailsPricelist(detailsPricelistTmp)
    }
    const getIdStore = () => {
        if (env.type === "company") {
            return env.stores[env.stores.findIndex(_ => _.shortName === catalogs[catalogs.findIndex(_ => _.id === parseInt(urlParams.idCatalog))].stores[0])].id
        }
        else
            return env.id
    }
    const buildLine = (line, index) => {
        let nbStores = 1
        let idMainStore = 0
        let otherStores = []

        if (env.type === "company") {
            let stores = catalogs[catalogs.findIndex(_ => _.id === parseInt(urlParams.idCatalog))].stores
            idMainStore = env.stores[env.stores.findIndex(_ => _.shortName === stores[0])].id
            nbStores = stores.length

            for (let i in stores) {
                otherStores.push(env.stores[env.stores.findIndex(_ => _.shortName === stores[i])])
            }

            if (otherStores.length > 0)
                delete otherStores[0]
        }
        else
            idMainStore = env.id

        return <div key={ index } className="lineList" style={{height: (100 + (detailsPricelist.includes(line.object.id) ? ((nbStores - 1) * 100) : 0)) + "px"}}>
            <div className="contLineList">
                <div className="name noBorderL">
                    {
                        detailsPricelist.includes(line.object.id)
                        && <p className="groupBy" onClick={ () => { detailStores(line.object.id) } }>Grouper les boutiques</p>
                    }
                    <p className={"value" + (env.type === "store" ? " solo" : "")}>{ line.object.name }</p>
                    {
                        env.type === "company"
                        && <p className={ "desc" + (detailsPricelist.includes(line.object.id) ? " store" : "") } onClick={ !detailsPricelist.includes(line.object.id) ? () => { detailStores(line.object.id) } : () => {} }>{ detailsPricelist.includes(line.object.id) ? catalogs[catalogs.findIndex(_ => _.id === parseInt(urlParams.idCatalog))].stores[0] : "Toutes les boutiques" }</p>
                    }
                </div>
                <div className="vatRate">{ buildVatRate(line, env.type === "company" ? (detailsPricelist.includes(line.object.id) ? idMainStore : 0) : 0) }</div>
                <div className="rule noBorderR">{ buildPriceVariation(line, env.type === "company" ? (detailsPricelist.includes(line.object.id) ? idMainStore : 0) : 0) }</div>
            </div>
            <div className="contStores">
                {
                    otherStores.map((store, indexStore) => (
                        <div key={ indexStore } className="contLineList">
                            <div className="name noBorderL">
                                <p className="value">{ line.object.name }</p>
                                <p className="desc store">{ store.name }</p>
                            </div>
                            <div className="vatRate">{ buildVatRate(line, store.id) }</div>
                            <div className="rule">{ buildPriceVariation(line, store.id, true) }</div>
                        </div>
                    ))
                }
            </div>
        </div>
    }
    const getIndexVatRateForValue = vatRateId => {
        let value = 0

        for (let i = 0; i < vatrates.length; i++) {
            if (vatrates[i].id === vatRateId) {
                value = i
                break
            }
        }

        return value
    }
    const buildVatRate = (line, idStore = 0) => {
        let idUsed = idStore

        if (idStore === 0)
            idUsed = getIdStore()

        let hasRule = false
        let applyingAvaible = true
        let textRule = ""
        let vatRule = line.categoryVatRule[idUsed].find(_ => _.price_list_id === pricelist.id)
        let vatRateId = null

        if (vatRule !== undefined)
            vatRateId = vatRule.vat_rate_id
        else
            vatRateId = line.object.vat_rate_id

        if (line.categoryVatRule[idUsed] !== null && line.categoryVatRule[idUsed].vat_rule_id !== null)
            applyingAvaible = false

        /*
        if (line.categoryVatRule[idUsed] !== null) {
            hasRule = true
            let maxLength = 20

            if (applyingAvaible)
                maxLength = 17

            textRule = category.name.substring(0, maxLength) + (category.name.length > maxLength ? "..." : "") + " : " + getVatRateById(line.categoryVatRule[idUsed].vat_rate_id).value
        }
        */

        return <div className="containerVertical">
            <div className={ "top" + (!hasRule ? " rowspan" : "") }>
                <InputSelect
                    attribute={line.object.id + "|vatRate|" + idStore}
                    returnType="pricelist"
                    classname="pricelist"
                    backgroundColor={ vatRule !== undefined ? pricelist.color : pricelists.find(_ => _.main === 1).color }
                    classError=""
                    value={ getIndexVatRateForValue(vatRateId)  }
                    list={ vatrates }
                    newItem={ true }
                    readonly={ shared }
                    handleChange={ changeEvent } />
            </div>
            {
                hasRule
                && <div className="bottom">
                    <p className={ (!applyingAvaible || shared) ? "extend" : "" }>{ textRule }</p>
                    {
                        (applyingAvaible && !shared)
                        && <ReApplyIcon fill={ pricelist.color } handleClick={ () => { recoveryEvent(line.object.id, idStore, "vatRate") } } />
                    }
                </div>
            }
        </div>
    }
    const buildPriceVariation = (line, idStore = 0, inStores = false) => {
        let idUsed = idStore

        if (idStore === 0)
            idUsed = getIdStore()

        let color = pricelist.color
        let hasRule = false
        let applyingAvaible = true
        let textRule = ""
        let priceRule = line.categoryPriceRule[idUsed].find(_ => _.price_list_id === pricelist.id)

        if (priceRule !== undefined && priceRule.parent_rule_id !== null)
            applyingAvaible = false

        /*
        if (line.categoryPriceRule[idUsed] !== null) {
            hasRule = true
            let maxLength = 30

            if (applyingAvaible)
                maxLength = 27

            textRule = category.name.substring(0, maxLength)
                + (category.name.length > maxLength ? "..." : "")
                + " : "
                + line.categoryPriceRule[idUsed].variationOperator
                + (
                    line.categoryPriceRule[idUsed].type === "PERCENT"
                        ? line.categoryPriceRule[idUsed].value
                        : new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(line.categoryPriceRule[idUsed].value).replace(" €", "")
                )
                + " "
                + (
                    line.categoryPriceRule[idUsed].type === "PERCENT"
                        ? "%"
                        : "EUR"
                )
        }
        */

        return <div className="containerVertical">
            <div className={ "top" + (!hasRule ? " rowspan" : "") }>
                {
                    priceRule !== undefined
                        ? <div className="variationContainer">
                            <div className={ "variationOperator perCategories" }>
                                <div className={ "bubble" + getBubblePlace(line, "variationOperator", idUsed) } style={{backgroundColor: color + "22", borderColor: color}} />
                                <div className={ "texts" }>
                                    <p className={shared ? "notAllowed" : ""} onClick={!shared ? () => { changeEvent(line.object.id + "|variationOperator|" + idStore, "pricelist", "-") } : () => {}}>-</p>
                                    <p className={shared ? "notAllowed" : ""} onClick={!shared ? () => { changeEvent(line.object.id + "|variationOperator|" + idStore, "pricelist", "+") } : () => {}}>+</p>
                                </div>
                            </div>
                            <div className="value">
                                <InputText
                                    attribute={ line.object.id + "|variationValue|" + idStore }
                                    returnType="pricelist"
                                    classname="pricelist extend"
                                    backgroundColor={ color }
                                    classError=""
                                    value={ priceRule.value }
                                    placeholder="0"
                                    readonly={ readonly }
                                    newItem={ true }
                                    toUppercase={null}
                                    handleChange={ changeEvent } />
                            </div>
                            <div className={ "type" }>
                                <div className={ "bubble" + getBubblePlace(line, "variationType", idUsed) } style={{backgroundColor: color + "22", borderColor: color}} />
                                {
                                    <div className="texts">
                                        <p className={shared ? "notAllowed" : ""} onClick={!shared ? () => { changeEvent(line.object.id + "|variationType|" + idStore, "pricelist", "DEVISE") } : () => {}}>€</p>
                                        <p className={shared ? "notAllowed" : ""} onClick={!shared ? () => { changeEvent(line.object.id + "|variationType|" + idStore, "pricelist", "PERCENT") } : () => {}}>%</p>
                                    </div>
                                }
                            </div>
                            <div className="remove">
                                {
                                    (priceRule !== undefined && !shared)
                                    && <div className="button">
                                        <CancelIcon click={ () => { removePrice(line.object.id, idStore) } } />
                                    </div>
                                }
                            </div>
                        </div>
                        : <div className="variationContainer">
                            <div className="add">
                                {
                                    !shared
                                    && <div className="button">
                                        <CrossIcon click={ () => { addPrice(line.object.id, idStore) } } />
                                    </div>
                                }
                            </div>
                        </div>
                }
            </div>
            {
                hasRule
                && <div className="bottom">
                    {
                        hasRule
                        && <div className="clearing fullWidth">
                            <p className={ (!applyingAvaible || shared) ? "extend" : "" }>{ textRule }</p>
                            {
                                (applyingAvaible && !shared)
                                && <ReApplyIcon fill={ color } handleClick={ () => { recoveryEvent(line.object.id, idStore, "variation") } } />
                            }
                        </div>
                    }
                </div>
            }
        </div>
    }
    const getBubblePlace = (line, attribute, idStore) => {
        let priceRule = line.categoryPriceRule[idStore].find(_ => _.price_list_id === pricelist.id)

        if (priceRule === undefined) return ""

        switch (attribute) {
            case "variationOperator":
                if (priceRule.variationOperator === "-") return " two"
                else if (priceRule.variationOperator === "+") return " three"
                break
            case "variationType":
                if (priceRule.type === "DEVISE") return ""
                else if (priceRule.type === "PERCENT") return " two"
                break
            default: break
        }
    }
    const addPrice = (idPricelist, idStore) => {
        if (handleAdd === undefined || handleAdd === null || shared) return
        handleAdd(idPricelist, idStore)
    }
    const removePrice = (idPricelist, idStore) => {
        if (handleRemove === undefined || handleRemove === null || shared) return
        handleRemove(idPricelist, idStore)
    }
    const changeEvent = (attribute, returnType, value) => {
        if (handleChange === undefined || handleChange === null || readonly) return
        handleChange(attribute, returnType, value)
    }
    const recoveryEvent = (idPricelist, idStore, type) => {
        if (handleRecovery === undefined || handleRecovery === null || readonly) return
        //handleRecovery(idPricelist, idStore, type, getMainPrice())
    }
    const detailStores = idPriceList => {
        let detailsTmp = detailsPricelist.slice()

        if (detailsTmp.includes(idPriceList))
            detailsTmp.splice(detailsTmp.indexOf(idPriceList), 1)
        else
            detailsTmp.push(idPriceList)

        setDetailsPricelist(detailsTmp)
    }

    useEffect(() => {
        defineDetailsPricelist()
    }, [])

    return (<div className={"inputPricelist byItem " + (classname !== undefined ? classname : "")}>
            <div className="header  small">
                <p className="vatTitle">TVA</p>
                <div className="contPriceTitles">
                    <p className="priceTitle">Règle</p>
                </div>
            </div>
            <div className="contScroll">
                {
                    lines.map((line, index) => buildLine(line, index))
                }
            </div>
        </div>)
}

export default InputPricelist
