import React, { useState, useEffect } from "react"
import $ from "jquery"
import LoaderCircle from "../../loader/LoaderCircle"
import CategoryController from "../../../stories/_catalog/Categories/CategoryController"
import VatRateController from "../../../stories/_catalog/VatRates/VatRateController"
import Product from "../../../stories/_catalog/Products/Product"
import FormInline from "../inline/FormInline"
import '../../../css/form/Form.css'

const FormManyProduct = props => {
    const { catalogId, handleClose } = props
    const [ loadingCategories, setLoadingCategories ] = useState(true)
    const [ loadingVatRates, setLoadingVatRates ] = useState(true)
    const [ categories, setCategories ] = useState([
        {
            value: "Choisir une catégorie",
            id: -1
        }, {
            value: "----------",
            id: -2
        }
    ])
    const [ vatRates, setVatRates ] = useState([
        {
            value: "Choisir une TVA",
            id: -1
        }, {
            value: "----------",
            id: -2
        }
    ])
    const [ globalError, setGlobalError ] = useState(null)
    const [ lines, setLines ] = useState([])
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameColumn: "name",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun",
            focus: true
        },
        {
            label: "Catégorie",
            attribute: "category_id",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameColumn: "category",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        },
        {
            label: "TVA",
            attribute: "vat_rate_id",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameColumn: "vatRate",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        },
        {
            noLabel: true,
            attribute: "variablePrice",
            inputType: "switch",
            returnType: "int",
            classnameColumn: "variablePrice",
            classnameInput: "inline"
        },
        {
            label: "Prix",
            attribute: "price",
            inputType: "text",
            returnType: "float",
            classnameColumn: "price",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "Prix",
            emptyText: "Aucun"
        },
        {
            label: "Réference",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameColumn: "reference",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "Réference",
            emptyText: "Aucune"
        }
    ])
    const catalog = JSON.parse(localStorage.getItem("catalogs")).find(_ => _.id === catalogId)

    const getCategories = () => {
        setLoadingCategories(true)

        const controller = new CategoryController()
        controller._callback = returnGetCategories
        controller.index(catalog.id, "", 0, 0, true)
    }
    const returnGetCategories = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setGlobalError(null)

                let categoriesTmp = categories.slice()

                for (let i in list) {
                    categoriesTmp.push({
                        value: list[i].name,
                        id: list[i].id,
                        object: list[i],
                        type: "categories"
                    });

                    for (let j in list[i].subCategories)
                        categoriesTmp.push({
                            value: "--- " + list[i].subCategories[j].name,
                            id: list[i].subCategories[j].id,
                            object: list[i].subCategories[j],
                            type: "subcategories"
                        });
                }

                setCategories(categoriesTmp)
                break
            default:
                setGlobalError("Impossible de récupérer les catégories")
                break
        }

        setLoadingCategories(false)
    }
    const getVatRates = () => {
        const controller = new VatRateController()
        controller._callback = returnGetVatRates
        controller.index()
    }
    const returnGetVatRates = (list, error, status) => {
        switch (status) {
            case 200:
                setGlobalError(null)

                let vatRatesTmp = vatRates.slice()

                for (let item in list) {
                    vatRatesTmp.push({
                        value: list[item].name,
                        id: list[item].id
                    })
                }

                setVatRates(vatRatesTmp)
                break
            default:
                setGlobalError("Impossible de récupérer les TVA")
                break
        }

        setLoadingVatRates(false)
    }
    const initRows = () => {
        let rowsTmp = rows.slice()

        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "category_id")].list = categories
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "vat_rate_id")].list = vatRates

        setRows(rowsTmp)
    }
    const addLine = (category_id, category_type, vatRate) => {
        const linesTmp = lines.slice()
        const product = new Product()

        product.catalog_id = catalog.id
        product.category_id = category_id
        product.category_type = category_type
        product.vat_rate_id = vatRate

        linesTmp.push(product)

        setLines(linesTmp)
    }
    const setObject = (key, object) => {
        let linesTmp = lines.slice()

        for(let index in linesTmp) {
            if (key === linesTmp[index].key) {
                linesTmp[index].object = object
                break
            }
        }

        setLines(linesTmp)
    }
    const scrollBottom = () => {
        const id = $(".overbox.manyForm");
        id.scrollTop(id[0].scrollHeight);
    }

    useEffect(() => {
        getCategories()
        getVatRates()
    }, [])
    useEffect(() => {
        if (!loadingCategories && !loadingVatRates)
            initRows()
    }, [loadingCategories, loadingVatRates])
    useEffect(() => {
        if (lines.length === 0)
            addLine(null, null, null)
    }, [rows])
    useEffect(() => {
        scrollBottom();
    }, [lines])

    return (
        <div className="clearing">
            {
                loadingCategories || loadingVatRates
                    ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5" />
                    : <div className="form noPadding">
                        <p className="titleForm center">Ajouter des produits dans "<b>{ catalog.name }</b>"</p>
                        {
                            globalError !== null
                            && <p className="globalError">{ globalError }</p>
                        }
                        <table className="manyForm">
                            <tbody>
                                <tr className="header">
                                    <th>Nom</th>
                                    <th>Catégorie</th>
                                    <th>TVA</th>
                                    <th>Variable</th>
                                    <th>Prix</th>
                                    <th>Référence</th>
                                    <th className="validate" />
                                </tr>
                                {
                                    lines.map((product, index) => (
                                        <FormInline
                                            key={ index }
                                            object={ product }
                                            type="products"
                                            rows={ rows }
                                            addLine={ addLine }
                                            setObject={ setObject }
                                            setGlobalError={ setGlobalError } />
                                    ))
                                }
                            </tbody>
                        </table>
                        <button className="cancel noMarginRight" onClick={ handleClose }>Fermer</button>
                        <div className="clearing" />
                    </div>
            }
        </div>
    )
}

export default FormManyProduct
